export enum FerrariVoucherEnum {
  CheckPromotions = 'Sprawdź promocje',
  Heading = 'Odbierz voucher\nna jazdę Ferrari',
  MubiPromotionsUrl = 'https://promocje-mubi.pl',
  PromotionHasEnded1 = 'Możliwość zgłoszenia udziału w akcji została zakończona.',
  PromotionHasEnded2 = 'Zgodnie z regulaminem akcji promocyjnej, zgłoszenie po voucher na przejazd Ferrari jest możliwe do 90 dni od rozpoczęcia akcji promocyjnej. Zachęcamy do skorzystania z innych promocji.',
  RegulationsInfo1 = 'Obowiązuje ',
  RegulationsInfo2 = 'regulamin',
  RegulationsUrl = 'https://mubi.pl/dokumenty/regulamin_ferrari_20240728.pdf',
}
