// tslint:disable:max-line-length

// ❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗
//        DON'T add any new values to this enum - create new file.
// ❗❗❗❗❗❗❗❗❗❗❗❗❗❗❗

export enum LabelEnum {
  AcceptAllRequiredConsents = 'Zaznacz wymagane zgody, aby przejść dalej',
  AccountNo = 'Numer rachunku',
  AccountNotActivated = 'Konto nie zostało jeszcze aktywowane!',
  AccountNumber = 'Numer konta',
  ActiveMessageWereSend = 'Wiadomość z linkiem aktywacyjnym została wysłana',
  AddToPolicy = 'Dodaj do polisy',
  Additional = 'Dodatkowo',
  AddonsCost = 'Koszt dodatków',
  Address = 'Adres',
  AdjustTheResults = 'Dostosuj wyniki',
  Advertisement = 'Reklama',
  Agree = 'Akceptuję',
  AlcoholClauseInfoMessage = 'Polisa jest rozszerzona o\xa0zdarzenia pod wpływem alkoholu jedynie dla osób, które są pełnoletnie w\xa0dniu rozpoczęcia podróży.',
  AllBrands = 'Wszystkie marki',
  AllFromPoland = 'Czy aktualnie jesteś w Polsce?',
  AllFromPolandPlural = 'Czy aktualnie jeste\u015Bcie w Polsce?',
  AllMakes = 'Wszystkie marki',
  AllOffers = 'Wszystkie oferty',
  AllowToBuyPoliceIn = 'umożliwia zakup polisy najwcześniej',
  Alright = 'W porządku',
  AndLong = 'oraz',
  AnswerTheQuestion = 'Odpowiedz na pytanie konkursowe',
  ApartmentNumber = 'Numer mieszkania',
  ApplicationSendToEMail = 'Wniosek ubezpieczeniowy wraz z linkiem do płatności wysłaliśmy również na Twojego maila',
  Attention = 'Uwaga!',
  BackToCalculation = 'Wróć do kalkulacji',
  BackToCalculator = 'Wróć do formularza',
  BackToCheckout = 'Wróć do edycji danych',
  BackToMainSite = 'Wróć do strony głównej',
  BackToOffers = 'Wróć do ofert',
  BackToUpsale = 'Wróć do wyboru opcji dodatkowych',
  BankTransferDataChangeAlreadyUsed1 = 'Ups, już raz potwierdziłeś tę zmianę danych do przelewu bonusu.',
  BankTransferDataChangeAlreadyUsed2 = 'Każdą zmianę można potwierdzić tylko raz',
  BankTransfersInfo = 'Przelewy realizowane są raz w miesiącu w ciągu 30 dni od opłacenia polisy przez użytkownika.',
  BeforeTakePhoto = 'Rozłóż dowód jak na zdjęciu. Jeżeli możesz, wyjmij\xa0go\xa0z\xa0foliowego\xa0pokrowca.',
  BeforeTakeReversePhoto = 'Odwróć dowód na drugą stronę, jak na zdjęciu.',
  BirthDate = 'Data urodzenia',
  BonusAfterEnding = 'po zakończeniu polisy',
  BonusAfterPurchasing = 'po zakupie polisy',
  BonusPayout1 = 'Odbierz bonus ',
  BonusPayout2 = '\xa0zł!',
  BonusPayoutAnonymousPostponedSuccessMessage = 'Twój bonus zostanie wypłacony po\xa0zakończeniu Twojej polisy lub będziemy się z\xa0Tobą kontaktować w\xa0przypadku nieudanej weryfikacji zgłoszenia.',
  BonusPayoutAnonymousSuccessMessage = 'Twój bonus wyślemy do\xa0Ciebie w\xa0ciągu 30\xa0dni lub będziemy się z\xa0Tobą kontaktować w\xa0przypadku nieudanej weryfikacji zgłoszenia.',
  BonusPayoutEmailQuestion = 'Na jaki e-mail powinniśmy Ci wysłać potwierdzenie zgłoszenia konkursowego?',
  BonusPayoutHint = 'Odpowiedz na pytanie konkursowe i\xa0uzupełnij pozostałe informacje.',
  BonusPayoutIncorrectPromotionType = 'Dla konkursu z\xa0losowaniem nagrody nie przysługuje wypłata bonusu.',
  BonusPayoutPostponedSuccessMessage = 'Twój bonus zostanie wypłacony po\xa0zakończeniu Twojej polisy!',
  BonusPayoutSuccessMessage = 'Twój bonus wyślemy do\xa0Ciebie w\xa0ciągu 30\xa0dni.',
  BonusPayoutVerificationHint = 'Wprowadź informacje podane w trakcie zakupu polisy.',
  BoughtPolicies = 'Kupione polisy',
  BuyNow = 'Kup teraz',
  Calculate = 'Kalkuluj',
  CalculateCheapestOCAC = 'Oblicz i kup polisę od 273 zł!',
  CalculateOCFrom1 = 'Oblicz i kup polisę od',
  CalculateOCFrom2 = '273 zł!',
  Calculation = 'Kalkulacja',
  CalculationFetchFailedNoPermission = 'Nie mogliśmy pobrać kalkulacji – wypełnij formularz ponownie',
  CalculationNumber = 'Numer porównania:',
  CalculationNumberHint = 'Konsultant może zapytać o\xa0ten numer w\xa0celu pobrania wprowadzonych danych.',
  Calculations = 'Kalkulacje',
  CalculationsAndPolicies = 'Kalkulacje i\xa0polisy',
  Call = 'Zadzwoń',
  CallbackFooterInfo = 'Twoje dane są bezpieczne, nigdy nie będą zbierane ani nie zostaną sprzedane do celów marketingowych.',
  CallbackHeaderInfo = 'Zamów rozmowę na wybraną godzinę, oddzwonimy!',
  CallbackRequested = 'Własnie otrzymaliśmy Twoją prośbę o kontakt. Niedługo nasz doradca zadzwoni do Ciebie.',
  Cancel = 'Anuluj',
  CannotRecalculateOffer = 'Niestety coś poszło nie tak!',
  Car = 'Samochód',
  CarHorsePower = 'Moc silnika',
  CarRegistrationNumber = 'Numer rejestracyjny samochodu',
  CaseReport = 'Zgłoś sprawę',
  Change = 'Zmień',
  ChangeConsents = 'Zmień zgody',
  ChangeData = 'Zmień dane',
  ChangeFileButton = 'Zmień plik',
  ChangePassword = 'Zmień hasło',
  CheckAll = 'Akceptuję wszystkie poniższe oświadczenia',
  CheckAllRequired = 'Akceptuję wszystkie wymagane oświadczenia',
  CheckAllRequiredByInsurer = 'Akceptuję wszystkie oświadczenia wymagane przez towarzystwo ubezpieczeniowe',
  CheckByOwn = 'Sprawdź sobie.',
  CheckDetailsBelow = 'Sprawdź szczegóły poniżej',
  CheckExactPrice = 'Poznaj dokładną cenę',
  CheckIfWeCanOfferYouALowerPriceOverThePhone = 'Sprawdź, czy przez telefon możemy zaoferować Ci niższą cenę.',
  CheckPrice = 'Sprawdź cenę',
  CheckPriceAtHarpie = 'Sprawdź cenę u\xa0agenta',
  CheckRenewalOffer = 'Odnów polisę samodzielnie i\xa0skorzystaj\xa0z ',
  CheckThatAllInformationInTheCalculatorIsCurrent = 'Sprawdź, czy podane informacje są nadal aktualne.',
  CheckTheOffer = 'Sprawdź ofertę',
  CheckTheOfferOnTheInsurersWebsite = 'Sprawdź ofertę na stronie ubezpieczyciela',
  CheckYourMail = 'Sprawdź swoją skrzynkę mailową.',
  CheckoutFixedSumInfo1 = 'Gratulacje! Warto jeździć bezpiecznie',
  CheckoutFixedSumInfo2 = 'Dzięki Twojej bezszkodowej historii zawarliśmy w\xa0ofercie stałą sumę ubezpieczenia\xa0AC.',
  CheckoutFormSectionInsurant2Label = 'Adres zamieszkania osoby zawierającej polisę',
  CheckoutPaymentSubmit1 = 'Aktualizacja danych 1/3',
  CheckoutPaymentSubmit2 = 'Wystawianie polisy 2/3',
  CheckoutPaymentSubmit3 = 'Pobieranie dokumentów 3/3',
  CheckoutPromotionalCodeInfo1 = 'Aby odebrać',
  CheckoutPromotionalCodeInfo2 = 'bon',
  CheckoutPromotionalCodeInfo3 = ', należy dokonać płatności za polisę ubezpieczeniową.',
  CheckoutRedirection = 'Poczekaj chwilę, przekierowujemy Cię na stronę ubezpieczyciela...',
  Choose = 'Wpisz lub wybierz',
  ChooseAReason = 'Wybierz powód:',
  ChooseAge = 'Wybierz wiek',
  ChosenOffer = 'Wybrana oferta',
  ChronicallySick = 'osoba przewlekle chora',
  City = 'Miejscowość',
  Clear = 'Wyczyść',
  ClearFilters = 'Wyczyść filtry',
  ClearForm = 'Wyczyść formularz',
  ClickHere = 'kliknij tutaj',
  ClickInLink = 'Na Twój adres e-mail wysłaliśmy link aktywacyjny. Wiadomość może być w folderze SPAM',
  ClicksInLink = 'Kliknięcia w link',
  Close = 'Zamknij',
  CloseWindow = 'Zamknij okno',
  CoOwner = 'Współwłaściciel',
  CoOwners = 'współwłaścicieli',
  CodeCopiedToClipboard = 'Skopiowano kod do schowka',
  Collapse = 'zwiń',
  CollapseOptions = 'Zwiń opcje',
  CollapseRest = 'Zwiń pozostałe',
  CollectInsurancesInOnePlace = 'Przechowuj polisy OC/AC w jednym miejscu',
  CollectedOffersQuestion1 = 'Pozostaw nam kontakt do siebie abyśmy mogli przedstawić\xa0Ci ',
  CollectedOffersQuestion2 = 'zebrane oferty',
  Compare = 'Dodaj do porównania',
  CompareInsurances = 'Porównaj ubezpieczenie samochodu online',
  CompareMotorInsurances = 'Porównaj ubezpieczenie motocykla online',
  CompareSelected = 'Pokaż porównanie',
  ComparisonNumber = 'Nr porównania',
  Confirm = 'Potwierdzam',
  ConfirmCode = 'Zatwierdź kod',
  ConfirmData = 'Potwierdź dane',
  ConfirmDataChange = 'Zatwierdź dane',
  ConfirmPayment = 'Potwierdź płatność za\xa0polisę',
  ConfirmPaymentHint = 'Dodaj potwierdzenie płatności np. od\xa0operatora płatności elektronicznych lub\xa0z\xa0konta bankowego.',
  ConfirmationBankAccountChangeFail = 'Nie udało się zmienić danych do przelewu bonusu.',
  ConfirmationBankAccountChangeSuccess = 'Zmiana danych do przelewu bonusu powiodła się!',
  ConfirmationEmailSuccess = 'Potwierdzenie adresu e-mail powiodło się!',
  ConfirmationOfEmailAddressFailed = 'Nie udało się potwierdzić adresu e-mail',
  Congratulation = 'Gratulacje!',
  CongratulationsLimitHasBeenReached = 'Gratulacje! Limit 2000 zł został osiągnięty.',
  ConsentRequired = 'Zaakceptuj powyższą zgodę',
  ConsentsUpdated = 'Zaktualizowano zgody',
  Continue = 'Kontynuuj',
  ContinueWithFacebook = 'Kontynuuj przez Facebook',
  ContinueWithGoogle = 'Kontynuuj przez Google',
  CookieInfo1 = 'Digibits sp. z o.o.  wykorzystuje pliki cookie oraz inne technologie, aby nasze strony pozostały niezawodne i bezpieczne oraz aby mierzyć ich skuteczność i zapewnić lepsze spersonalizowanie doświadczeń zakupowych i personalizację reklam. W tych celach zbieramy dane o użytkownikach, ich zachowaniach oraz urządzeniach.',
  CookieInfo1Old = 'Dla zapewnienia najwyższej jakości usług nasza strona korzysta z plików cookies. Dalsze korzystanie ze strony, bez zmiany ustawień przeglądarki, oznacza, że wyrażasz zgodę na wykorzystanie plików cookies, zgodnie z naszą ',
  CookieInfo2Old = 'Polityką Prywatności',
  CookiePopupButton = 'Odśwież stronę',
  CookiePopupHeading1 = 'Chcesz pojechać dalej?',
  CookiePopupHeading2 = 'Włącz proszę pliki cookies.',
  CookiePopupInfo = 'Pliki cookies w\xa0Twojej przeglądarce są\xa0wyłączone. Włącz ich obsługę, aby kalkulator OC\xa0AC działał prawidłowo.',
  CookiePopupLink = 'Jak włączyć pliki cookies?',
  Cookies = 'Ciasteczka',
  CopiedToClipboard = 'Skopiowano do schowka',
  Copy = 'Kopiuj',
  CopyOfferDetails = 'Kopiuj dane oferty',
  CopyToClipboard = 'Kopiuj do schowka',
  CopyToClipboardError = 'Nie udało się skopiować do schowka, spróbuj skopiować ręcznie',
  CovidProtection = 'Ochrona COVID',
  CreateAccount = 'Utwórz konto',
  CreateNewCalculation = 'Zrób nową kalkulację',
  CreatingMubiAccount = 'Tworzenie konta Mubi',
  CurrentInsuranceCompany = 'Z której firmy ubezpieczeniowej rezygnujesz?',
  CurrentPassword = 'Aktualne hasło',
  DataChangeRequestExpired1 = 'Link do potwierdzenia zmiany już wygasł, wygeneruj nowy link w zakładce ',
  DataChangeRequestExpired2 = 'moje konto',
  DateFormat = 'DD-MM-RRRR',
  DayAfterTomorrow = 'Pojutrze',
  DayOfDeparture = 'Termin wyjazdu: ',
  DaysBeforeProtectionBegins = 'dni przed rozpoczęciem ochrony',
  DeadlineForPickupHasExpired = 'Termin odbioru minął',
  DeadlineForPickupHasExpiredDesc = 'Przykro nam, ale minęło już 90 dni od zawarcia polisy z kodem promocyjnym. Twój bonus nie jest już aktywny. Śledź nasze akcje promocyjne przy zakupie kolejnego ubezpieczenia i nie zwlekaj ze zgłoszeniem po nagrodę!',
  Decline = 'Nie akceptuję',
  Destination = 'Cel: ',
  Details = 'Szczegóły',
  DetailsOfOfferNr = 'Szczegóły oferty nr: ',
  Different = 'Inny',
  Direction = 'Kierunek: ',
  DisableNotifications2 = ' z',
  DisableNotifications3 = ' r.',
  Discount15Percent1 = '15%',
  Discount15Percent2 = ' zniżki',
  DiscoverPossibilities = 'Odkryj możliwości Twojego Konta Mubi!',
  DoYouWantToReturnToTheSavedComparison = 'Czy chcesz wrócić do zapisanego porównania?',
  DoYouWantToStartAgain = 'Chcesz zacząć od nowa?',
  DocumentLoading = 'Wczytujemy dokument, to potrwa chwilkę',
  DocumentSent = 'Dokumenty oraz informacje o płatności  wysłaliśmy również na Twojego maila',
  Doors = 'drzwi',
  DoorsCount = 'Liczba drzwi',
  DownloadApplication1 = 'Pobierz',
  DownloadApplication2 = 'wniosek',
  DownloadApplication3 = ', a po dokonaniu płatności otrzymasz polisę.',
  DownloadDocument = 'Pobierz dokument',
  DownloadOWU = 'Pobierz OWU',
  DownloadPolicy = 'Pobierz polisę',
  DropFileHere = 'Lub upuść plik tutaj',
  EditProfile = 'Edytuj profil',
  Efficiency = 'Skuteczność',
  EmailAddress = 'Adres e-mail',
  EmailAlreadyInUse = 'Podany email jest już zajęty',
  EmailNotExist = 'Konto na podany adres e-mail nie istnieje. Zarejestruj się',
  EmailPlaceholder = 'twoj@mail.com',
  EmailSmsSent = 'Na podany telefon oraz e-mail wyślemy link do Twojej kalkulacji.',
  EndLater = 'i wróć do niego później!',
  EngineCapacity = 'Pojemność silnika',
  EnterCode = 'Kod z SMS',
  EnterData = 'Uzupełnij dane',
  EnterEmailAddresses = 'Wprowadź adresy e-mail',
  EnterLead = 'Wprowadź leada',
  EnterPesel = 'Podaj PESEL',
  EnterSmsCode1 = 'Wpisz kod z SMS',
  EnterSmsCode2 = 'Kod powinien dojść w ciągu ',
  EnterSmsCode3 = ' sekund',
  EnterYourEmailAddressToBeAbleToGetBackToOffers = 'Podaj e-mail, aby móc wrócić do ofert',
  EnterYourEmailToProceed = 'Aby przejść dalej podaj swój adres e-mail',
  ErrorOccurredContactSalesSupportDepartment = 'Jeśli chcesz załatwić sprawę pilnie, skontaktuj się z Zespołem Wsparcia.',
  ErrorOccurredContactUs1 = 'Ups! Wygląda na to, że wystąpił błąd. Nie martw się, oddzwonimy do Ciebie i\xa0postaramy się pomóc.',
  ErrorOccurredContactUs2 = 'Jeśli chcesz załatwić sprawę pilnie, skontaktuj się z\xa0nami pod numerem 22\xa022\xa010\xa0100. ',
  ErrorOccurredContactUs3 = 'Jeśli masz pytania, skontaktuj się z\xa0nami pod numerem 22\xa022\xa010\xa0100.',
  ErrorOccurredInvalidPhoneNumber = 'Ups! Wprowadzony numer telefonu jest nieprawidłowy. Podaj poprawny numer, aby przejść do\xa0zakupu polisy.',
  ErrorOccurredPivotalDataChanged1 = 'Ups! Wygląda na to, że zmodyfikowałeś dane tego ubezpieczenia w innej zakładce przeglądarki.',
  ErrorOccurredPivotalDataChanged2 = 'Odśwież stronę',
  ErrorOccurredPivotalDataChanged3 = ', żebyśmy mogli zaktualizować dane.',
  ErrorOccurredTooManyUnpaidPolicies = 'Nie możesz przejść do zakupu polisy ze względu na zbyt dużą liczbę nieopłaconych polis na Twoim koncie.',
  ErrorOccurredTravel1 = 'Ups! Wygląda na to, że wystąpił błąd. Sprawdź poprawność wprowadzonych danych.',
  ErrorOccurredTravel2 = 'Jeśli potrzebujesz pomocy skontaktuj się z\xa0nami pod adresem: ',
  ErrorOccurredTravel3 = ' lub\xa0wybierz ofertę innego ubezpieczyciela.',
  Europe = 'Europa',
  Expand = 'rozwiń',
  ExpressCalculation = 'Ekspresowa kalkulacja',
  ExpressWayToCalculate = 'Ekspresowy sposób na kalkulacje',
  Facebook = 'Facebook',
  FacebookChatGreeting = 'Cześć, w czym możemy Ci pomóc?',
  FailedHiProPaymentSubtitle = 'Opłać polisę jednorazowo lub ponownie wybierz ofertę na\xa0rankingu, by\xa0złożyć wniosek raz jeszcze.',
  FailedHiProPaymentTitle = 'Raty zostały anulowane, opłać polisę w\xa0inny sposób',
  FakeOfferText = 'Uzupełnij dane, aby\xa0wyświetlić ofertę',
  FieldAllRequiredData = 'Podaj wszystkie dane pojazdu, aby przejść dalej',
  FieldRequired = 'Wypełnij to pole, aby przejść dalej',
  FieldRequiredTravel = 'Odpowiedz na to pytanie, aby przejść dalej',
  FileRequired = 'Nie wybrano pliku',
  FileTooLarge = 'Plik jest za duży, spróbuj ponownie z mniejszym',
  FillData = 'Uzupełnij dane',
  FillDataHint = 'Podanie tych informacji jest bezpieczne i nie będą one przetwarzane w celach innych, niż przygotowanie oferty.',
  FillDataInfo = '73% klientów uzyskuje niższe ceny uzupełniając dane.',
  FillDataToFindOutPrice = 'Uzupełnij dane aby poznać cenę!',
  FillEmailAddress = 'Podaj adres email',
  FillPreviousQuestions = 'Najpierw uzupełnij poprzednie pola!',
  FillVin = 'Wypełnij VIN',
  FillVinToGetOffer = 'Podaj numer VIN, żeby otrzymać ofertę Ergo Hestii',
  FilterPlaceholder = 'Filtruj',
  Finalize = 'Finalizuj zakup',
  FirstName = 'Imię',
  FirstTraveler = 'Podróżny 1:',
  ForYouAndFriend = 'dla Ciebie i\xa0znajomego',
  FriendsBuyPolicy = 'Twoi znajomi klikając w\xa0link, aktywują bonus, szukają polisy na Mubi i\xa0dokonują zakupu.',
  From = 'Od',
  FromCheapest = 'Sortuj od najtańszych',
  FromMostExpensive = 'Sortuj od najdroższych',
  FromTheRegistrationCertificate = 'z dowodu rejestracyjnego',
  FuelType = 'Rodzaj paliwa',
  FunctionalCookie = 'Funkcjonalne pliki cookie',
  FunctionalCookieDescription = 'Korzystamy z tych danych, aby zapewnić dodatkowe funkcje, które poprawią doświadczenia związane z zakupami, oraz aby zapobiegać błędom.',
  GetCepikData = 'Pobierz dane z CEPiK',
  GetTheBonus = 'Odbierz bonus!',
  GettingCepikData = 'Pobieram dane z CEPiK...',
  GettingSick = 'Zachorowanie',
  Glass = 'Szyby',
  GoBack = 'Wróć',
  GoBackToCalc = 'Wróć do kalkulatora OC/AC',
  GoToDocuments = 'Przejdź do ',
  GoToEmail = 'Przejdź do poczty',
  GoToMainSite = 'Przejdź do strony głównej',
  GoToMubi = 'Przejdź do Mubi',
  Google = 'Google',
  GracePeriodInfoMessage = 'Ochrona rozpocznie się 4h po opłaceniu polisy',
  HasAnythingChangedOverThePastYear = 'Czy coś się zmieniło w ciągu ostatniego roku?',
  HaveQuestions = 'Masz pytania?',
  HaveYouAccount = 'Masz już konto?',
  HaveYouForgetPassword = 'Nie pamiętasz hasła?',
  HiProApplicationError1 = 'Wniosek ratalny odrzucony',
  HiProApplicationError2 = 'Przepraszamy, ale niestety Twój wniosek ratalny został odrzucony przez dostawcę usługi finansującej. Wybierz inną metodę płatności',
  Horsepower = 'KM',
  HowItWorks = 'Jak to działa?',
  IAlreadyHaveAnotherCar = 'Mam już inne auto',
  IDonTHaveAnyCarAnymore = 'Nie mam już żadnego auta',
  IfNotRedirectedAutomatically = 'Jeśli przekierowanie nie nastąpi automatycznie',
  IfYouHaveAQuestions = 'W razie pytań skontaktuj się z nami:',
  IncidentsUnderInfluenceOfAlcohol = 'Zdarzenia pod wpływem alkoholu',
  IncorrectDate = 'Wprowadzono nieprawidłową datę',
  IncreaseBonusBtn = 'Odbierz ',
  IncreaseBonusBtnBasic = ' zł po zakupie polisy',
  IncreaseBonusBtnPostponed = ' zł po zakończeniu polisy',
  IncreaseBonusHint1 = 'Aby odebrać większy bonus ',
  IncreaseBonusHint2 = ' zł, samochód nie może zostać sprzedany w ciągu 12 miesięcy od zawarcia polisy.',
  IncreaseBonusHint3 = 'Bonus ',
  IncreaseBonusHint4 = ' zł zostanie wypłacony po zakończeniu polisy.',
  IncreaseBonusTitle1 = 'Powiększ ',
  IncreaseBonusTitle2 = 'bonus ',
  IncreaseBonusTitle3 = 'do ',
  IncreaseBonusTitle4 = ' zł!',
  IndicatorStep1 = '1. Start',
  IndicatorStep2c = '2. Samochód',
  IndicatorStep2m = '2. Jednoślad',
  IndicatorStep3 = '3. Kierowca',
  IndicatorStep4 = '4. Oferty',
  InfoekspertHeading = 'Niektóre towarzystwa ubezpieczeniowe korzystają z katalogu pojazdów Infoekspert. Wskaż swój pojazd w tym katalogu. ',
  InformationAboutLink1 = 'Dzięki, rozpoczęliśmy poszukiwania najtańszej polisy dla\xa0Ciebie.',
  InformationAboutLink2 = 'Odezwiemy się niebawem :)',
  InformationAboutLink3 = 'Możesz spodziewać się kontaktu z\xa0naszej strony',
  InformationAboutLink4 = 'w\xa0godzinach 9-21 (w\xa0tygodniu) lub 9-17 (w\xa0sobotę)',
  InformationUpdated = 'Informacje zaktualizowane!',
  Input = 'Wprowadź',
  InputMissingDataToSeeMoreOffers = 'Podaj brakujące dane, aby zobaczyć więcej ofert!',
  Installments = 'rat',
  InstallmentsFrom = 'Raty od',
  InstallmentsRrsoToolTip = 'Reprezentatywny przykład dla pożyczki ratalnej: Rzeczywista Roczna Stopa Oprocentowania (RRSO) wynosi 75,45%, całkowita kwota kredytu (bez kredytowanych kosztów) 1000\xa0zł, całkowita kwota do zapłaty 1287,25\xa0zł, oprocentowanie zmienne 18,50%, całkowity koszt kredytu 287,25\xa0zł (w\xa0tym: kredytowana prowizja 183,00\xa0zł, odsetki 104,25\xa0zł), 9\xa0miesięcznych równych rat w\xa0kwocie 128,56\xa0zł i\xa0ostatnia rata w\xa0wysokości 130,21\xa0zł. Kalkulacja została dokonana na dzień 5.02.2024\xa0r. na reprezentatywnym przykładzie.\n\nWysokość pożyczki, wysokość raty oraz liczba dostępnych rat są ustalane indywidualnie w\xa0oparciu o\xa0ocenę zdolności kredytowej klienta. Koszt pożyczki uzależniony jest również od okresu na jaki jej udzielono.',
  InstallmentsToolTipSubHeader1 = 'Rozłóż kwotę na ',
  InstallmentsToolTipSubHeader2 = ' wygodnych rat Mubi i\xa0zamiast\xa0obciążać swój budżet, płać co miesiąc!',
  InstallmentsUnavailableInfo1 = 'Ups! Wygląda na\xa0to, że\xa0niestety nie możemy zaproponować Ci płatności w\xa0',
  InstallmentsUnavailableInfo2 = ' ratach. Nie martw się, możesz wybrać wyżej dostępną formę płatności.',
  InstallmentsZeroRrsoToolTip = 'Reprezentatywny przykład dla pożyczki ratalnej: Rzeczywista Roczna Stopa Oprocentowania (RRSO) wynosi 0%, całkowita kwota kredytu (bez kredytowanych kosztów) 1000 zł, całkowita kwota do zapłaty 1000 zł, oprocentowanie stałe 0%, całkowity koszt kredytu 0 zł (w tym: kredytowana prowizja 0 zł, odsetki 0 zł), 5 miesięcznych rat równych w kwocie 166,66 zł i ostatnia rata w wysokości 166,70 zł. Dane aktualne na dzień 13.12.2022 r. Wysokość pożyczki, wysokość raty oraz liczba dostępnych rat są ustalane indywidualnie w oparciu o ocenę zdolności kredytowej klienta. Koszt pożyczki uzależniony jest również od okresu na jaki jej udzielono.',
  Insulation = 'Izolacja',
  InsurancPolicyDataSection = '3. Dane polisy',
  Insurance = 'Ubezpieczenie',
  InsuranceComparison = 'Porównywarka ubezpieczeń',
  InsuranceFrom = 'Ubezpieczenie od',
  InsurancePeriod1 = 'Łącznie',
  InsurancePeriod2 = 'dzień',
  InsurancePeriod2Plural = 'dni',
  InsurancePeriod3 = 'ochrony',
  InsurancePolicyEnding = 'Kiedy kończy się Twoja polisa?',
  InsurancePolicyNumber = 'Numer polisy do wypowiedzenia',
  InsurancePrice = 'Cena ubezpieczenia',
  InsuranceProtectionPeriod = 'Okres ochrony ubezpieczeniowej',
  InsuranceStartDate = 'Data startu ubezpieczenia',
  InsuranceSumInEuro = 'Przeliczone automatycznie z kwoty: ',
  Insurant = 'Ubezpieczający: ',
  InsurantPesel = 'Numer PESEL osoby ubezpieczającej',
  Insured = 'Ubezpieczeni: ',
  InsuredPerson = 'osoba ubezpieczona',
  InsuredPersons1 = 'osoby ubezpieczone',
  InsuredPersons2 = 'osób ubezpieczonych',
  InsurerAscending = 'Ubezpieczyciel – od A do Z',
  InsurerDescending = 'Ubezpieczyciel – od Z do A',
  InvalidAge = 'Wprowadź poprawny wiek',
  InvalidCarLongRegistrationNumber = 'Z powodu krótkiego numeru rejestracyjnego Twój wniosek wymaga dodatkowej weryfikacji. Skontaktuj się z naszym doradcą pod numerem tel. 22\xa022\xa010\xa0100.',
  InvalidCarLongRegistrationNumberAgency = 'Z powodu krótkiego numeru rejestracyjnego Twój wniosek wymaga dodatkowej weryfikacji. Skontaktuj się z Zespołem Wsparcia.',
  InvalidCarRegistrationNumber = 'Wpisz poprawny numer rejestracyjny',
  InvalidChar = 'W polu są niedozwolone znaki. Wpisz poprawne dane',
  InvalidDate = 'Wpisana data jest nieprawidłowa',
  InvalidEmail = 'Wprowadź poprawny adres e-mail',
  InvalidFileExtension = 'Wybierz plik w formacie PDF, BMP, GIF, JPE, JPG, PNG, TIFF lub x-panasonic-raw.',
  InvalidHouseNumber = 'Wprowadź poprawny numer',
  InvalidIban = 'Wprowadź poprawny numer konta',
  InvalidIdCardNumber = 'Wprowadź poprawny numer dowodu',
  InvalidMinSelectedNo = 'Zaznacz więcej opcji',
  InvalidNipNumber = 'Ups! Wprowadzony numer NIP jest nieprawidłowy.',
  InvalidNumber = 'Wprowadź poprawną liczbę',
  InvalidPassword = 'Wpisz poprawne hasło',
  InvalidPesel = 'Numer PESEL jest niepoprawny',
  InvalidPhoneNumber = 'Ups! Wprowadzony numer telefonu jest nieprawidłowy.',
  InvalidSmsCode = 'Kod SMS jest niepoprawny',
  InvalidStreetName = 'Wprowadź poprawną nazwę ulicy',
  InvalidToken = 'Link wygasł',
  InvalidVin = 'Numer VIN jest niepoprawny',
  InvalidZipCode = 'Kod pocztowy jest niepoprawny',
  Ipsum = 'Ipsum',
  IsNotActive = 'jest nieaktywny',
  ItSGoodThatYouReComingBackToUs = 'Dobrze, że do nas wracasz!',
  ItTakes30Seconds = '30 sekund',
  ItTakes3Minutes = '3 minuty',
  ItsAll = 'To już wszystko! :)',
  Lack = 'Brak',
  LastName = 'Nazwisko',
  LastStepBeforePolicyDownload = 'Ostatni krok po którym pobierzesz polisę',
  LeadSaveError = 'Zapisanie leada nie powiodło się',
  LeadSaveSuccess = 'Zapisano leada',
  LeasingHint = 'Jeszcze nie\xa0porównujemy ofert dla aut w\xa0leasingu, ale nasi partnerzy chętnie Ci pomogą w\xa0znalezieniu atrakcyjnej oferty.',
  Less = 'Mniej',
  LimitReached1 = 'Gratulacje!',
  LimitReached2 = 'Limit 2000\xa0zł został osiągnięty! Możliwość polecania zostanie odblokowana za 12\xa0miesięcy.',
  LinkClicks = 'Kliknięcia w link',
  LinkWithCalculation = 'Na poniższy numer przedstawimy Ci najtańsze, spersonalizowane oferty',
  LoadCarData1 = 'Wczytaj dane ',
  LoadCarData2 = 'swojego samochodu',
  LoadFiles = 'Wczytaj pliki',
  LoadImage = 'Chcę wczytać zdjęcia z komputera',
  LoadSimpleDocuments1 = 'Jeśli masz przy sobie Dowód Rejestracyjny, ',
  LoadSimpleDocuments2 = 'obliczymy najtańsze oferty za Ciebie!',
  LoadSimpleDocumentsHowItWorks1 = 'Przygotuj swój dowód rejestracyjny i\xa0opcjonalnie ostatnią polisę auta.',
  LoadSimpleDocumentsHowItWorks2 = 'Wykonaj i prześlij zdjęcia korzystając z przycisku “Prześlij zdjęcie dowodu”.',
  LoadSimpleDocumentsHowItWorks3 = 'Wykonane zdjęcia zostaną użyte do\xa0obliczenia najtańszych polis ubezpieczeniowych dla\xa0Ciebie. Twoje dane będą bezpieczne i\xa0zaszyfrowane.',
  LoadSimpleDocumentsMobile1 = 'Jeśli masz przy sobie Dowód Rejestracyjny, ',
  LoadSimpleDocumentsMobile2 = 'obliczymy najtańsze oferty za Ciebie!',
  LoaderModalBottomText = 'Właśnie zbieramy dla Ciebie',
  LoaderModalFooterText1 = 'Szukamy najtańszych ',
  LoaderModalFooterText2 = 'o',
  LoaderModalFooterText3 = 'fert…',
  LoaderModalHeader = 'Znasz 3 powody, dla których warto kupić polisę w mubi?',
  LoaderModalHeader1 = 'Nie tracisz czasu',
  LoaderModalHeader2 = 'Nic nie ryzykujesz',
  LoaderModalHeader3 = 'Uzyskasz fachową pomoc',
  LoaderModalSubtitle1Line1 = 'oszczędzisz do 70%',
  LoaderModalSubtitle1Line2 = 'na wyborze najtańszej polisy',
  LoaderModalSubtitle2Line1 = 'zarobisz do 2000 zł',
  LoaderModalSubtitle2Line2 = 'polecając nas znajomym',
  LoaderModalSubtitle3Line1 = 'nic nie ryzykujesz',
  LoaderModalSubtitle3Line2 = 'polisę możesz zwrócić w ciągu 30 dni',
  LoaderModalSubtitleLead = 'Kupując na Mubi ',
  LoaderModalText1 = 'Możesz wykorzystać już wpisane przez Ciebie dane, aby szybko kupić polisę',
  LoaderModalText2 = 'Z każdej polisy możesz zrezygnować w ciągu 30 dni od zakupu',
  LoaderModalText3 = 'Eksperci mubi pomogą Ci, jeśli masz jakieś pytania lub wątpliwości',
  LoaderModalTravelFooterText1 = 'Wczytujemy ',
  LoaderModalTravelFooterText2 = 'o',
  LoaderModalTravelFooterText3 = 'ferty. Proszę czekać...',
  LoaderModalTravelHeader = 'Szukamy najtańszych ofert...',
  LoaderModalTravelSubtitle2Line1 = 'znajdziesz ubezpieczenia',
  LoaderModalTravelSubtitle2Line2 = 'z szerokim zakresem ochrony',
  LoaderModalTravelSubtitle3Line1 = 'polisę otrzymasz na maila',
  LoaderModalTravelSubtitle3Line2 = 'w kilka minut!',
  Loading = 'Wczytywanie...',
  LogIn = 'Zaloguj się',
  LogOut = 'Wyloguj się',
  LoggedAs = 'Zalogowano jako:',
  LoginAbort = 'Przerwałeś proces logowania, spróbuj ponownie',
  LoginAllowToTransferEmailAddressToFacebook = 'Aby zalogować się przez aplikację Facebook, wyraź zgodę na przekazanie swojego adresu email do mubi.pl',
  LoginAllowToTransferEmailAddressToGoogle = 'Aby zalogować się przez Google, wyraź zgodę na przekazanie swojego adresu email do mubi.pl',
  LoginExists = 'Podany login już istnieje. Zaloguj się',
  LoginToAccount = 'Zaloguj się do konta Mubi',
  LoginWithFacebook = 'Zaloguj się przez Facebook',
  LoginWithGoogle = 'Zaloguj się przez konto Google',
  LoginWithOauth = 'Logowanie mailem zablokowane. Zaloguj się za pomocą Google lub Facebook.',
  Lorem = 'Lorem',
  LuggageInsurance = 'Ubezpieczenie bagażu',
  MailHasBeenSent = 'Mail z zaproszeniem został wysłany',
  MailHasNotBeenSent = 'Nie mogliśmy wysłać maila',
  Make = 'Marka',
  MakeOrSelectPhoto = 'Zrób lub wybierz z galerii zdjęcie kodu aztec z Twojego dowodu rejestracyjnego jak na poniższym przykładzie',
  MarketingCookie = 'Pliki cookie do celów marketingowych',
  MarketingCookieDescription = 'Digibits sp. z o.o. gromadzi informacje o historii użytkownika, aby zapewnić właściwe treści sponsorowane dotyczące naszych produktów (oraz produktów oferowanych przez naszych partnerów i marki w naszych sklepach). Aby wybrać treści, które odpowiadają Twoim zainteresowaniom, możemy na przykład użyć informacji dotyczących Twoich zakupów oraz interakcji na stronie. Możemy udostępniać te informacje, takie jak zaszyfrowany adres e-mail lub szczegóły urządzenia oraz identyfikator klienta stronom trzecim, np. serwisom Facebook lub Google. Celem jest prezentowanie właściwych reklam na stronach i w aplikacjach stron trzecich. Możemy także łączyć te dane między różnymi urządzeniami, z których korzystasz, jak również przetwarzać dane dotyczące reklam (przykładowo, czy kliknięcie reklamy doprowadziło do zakupu). Służy to ocenie skuteczności reklamy oraz umożliwia jej fakturowanie.',
  Medic = 'Pomoc medyczna',
  MedicalCostsDescending = 'Koszty leczenia - najwyższe',
  MedicalExpenses = 'Koszty leczenia',
  Menu = 'Menu',
  MessageContent = 'Treść wiadomości',
  MessageCorrectSent = 'Wiadomość wysłana poprawnie',
  MessageSent = 'Wiadomość z instrukcją zmiany hasła została wysłana.',
  MessageSentOnNumber = 'Wiadomość wysłana na numer',
  MessageWithLinkSend = 'Wiadomość z linkiem do potwierdzenia zmiany danych została wysłana',
  Messenger = 'Messenger',
  Model = 'Model',
  More = 'Więcej',
  MoreOffers = 'Więcej ofert',
  MubiExpert = 'Ekspert mubi',
  MultipleVariants = 'Wiele wariantów',
  MyAccount = 'Moje konto',
  NetworkError = 'Wystąpił problem z połączeniem internetowym',
  New = 'Nowość!',
  NewCalculation = 'Nowa kalkulacja',
  NewCarSelectDescription1 = 'Korzystając z informacji w dowodzie rejestracyjnym, podaj dane swojego pojazdu, takie jak: marka, rok produkcji, rodzaj paliwa, model i wersja. ',
  NewCarSelectDescription2 = 'Jeśli zostaniesz o\xa0to poproszony, potwierdź swój samochód w\xa0katalogu Infoekspert.',
  NewPassword = 'Nowe hasło',
  Next = 'Przejdź dalej',
  No = 'Nie',
  NoAccount = 'Nie masz konta?',
  NoCalculation = 'Brak kalkulacji',
  NoConsents = 'Brak zgód',
  NoInformation = 'Brak informacji',
  NoOptions = 'Brak wyników',
  NoPolicies = 'Brak polis',
  NoShoppingOpportunity1 = 'Ubezpieczyciel pozwala na zakup najwcześniej na',
  NoShoppingOpportunity2 = 'dni przed startem ochrony.',
  NoShoppingOpportunity3 = 'Jeżeli chcesz skorzystać z tej oferty',
  NoShoppingOpportunity4 = 'zmień datę rozpoczęcia polisy',
  NoShoppingOpportunity5 = 'lub wróć do porównania bliżej startu ubezpieczenia.',
  NoTimeToFillOut1 = 'Nie masz czasu na fomularz?',
  NoTimeToFillOut2 = 'Wypełnimy go za Ciebie:',
  NotUniquePesel = 'Podany pesel został już użyty na formularzu w polu',
  NotUniquePeselShort = 'Podany pesel został już użyty na\xa0formularzu',
  NumberOfInstallments = 'Liczba rat',
  NumberOfTravelers = 'Liczba podróżnych: ',
  OK = 'OK',
  ObverseOfTheRegistrationCertificate = 'Zewnętrzna strona Dowodu Rejestracyjnego',
  OcAndAcCalculator = 'Kalkulator OC i AC',
  OfferCalculationInfo = 'Kalkulacja oferty z\xa0wybranym zakresem ubezpieczenia oraz zakup będą możliwe po\xa0przejściu na\xa0stronę ubezpieczyciela.',
  OfferDetails = 'Szczegóły oferty',
  OfferForm1 = 'ofert',
  OfferForm2 = 'oferty',
  OfferForm3 = 'ofertę',
  OffersComparison = 'Porównanie ofert',
  OffersViaEmailSend = 'Oferta wysłana! Sprawdź swoją skrzynkę e-mailową.',
  OneInstallment = 'Rata',
  OneSecurity = 'jedno zabezpieczenie',
  OneTimePayment = 'Płatność jednorazowa',
  OnlinePayment = 'Płatność online',
  OnlinePaymentConfirm = 'potwierdzamy zakup i\xa0płatność za\xa0polisę!',
  OnlinePaymentFail = 'Twoja płatność nie powiodła się. ',
  Open = 'Otwórz',
  OperatorOffersViaEmailSend = 'Oferta wysłana na adres: ',
  Options = 'Opcje',
  Or = 'lub',
  OrCompleteTheForm = 'Lub uzupełnij formularz:',
  OrderACall = 'Zamów kontakt',
  OrderACallOrCall = 'Zamów rozmowę lub zadzwoń',
  OrderPayoff = 'Uzupełnij dane do\xa0przelewu',
  OtherPerson = 'Inna osoba',
  OurGoal = 'Naszym celem jest, aby polscy kierowcy mogli łatwo i wygodnie wyszukiwać najlepsze ubezpieczenia OC i AC.',
  Owner = 'Właściciel',
  OwnerAddress = 'Adres zameldowania właściciela',
  OwnerDataSection = '2. Dane właściciela',
  Owners = 'właściciela',
  Paid = 'Opłacone',
  PaidPolicies = 'Opłacone polisy',
  PaidProvision = 'Wypłacona prowizja',
  PartnerId = 'Partner ID',
  Password = 'Hasło',
  PasswordChange = 'Zmiana hasła',
  PasswordChanged = 'Hasło zostało zmienione',
  PasswordNotStrongEnough = 'Minimum 6 znaków, mała i wielka litera oraz cyfra',
  PasswordRepeat = 'Powtórz hasło',
  PasswordsNotMatching = 'Hasła nie pasują do siebie.',
  PayBeforeStart = 'Pamiętaj o jej opłaceniu przed startem ochrony.',
  PayBeforeStartToActive = 'Opłać ubezpieczenie przed startem, aby było aktywne.',
  PayForInsurance = 'Kupuję',
  PayForThePolicy = ', zapłać za polisę.',
  PayNow = 'Zapłać teraz',
  PayThePolicy = 'Opłać polisę',
  Payment = 'Płatność',
  PaymentArrears = 'ma dla Ciebie wyłącznie płatność online bez rozbicia na raty.',
  PaymentArrearsReminder1 = 'Pamiętaj o\xa0opłaceniu wniosku w ciągu 7 dni.',
  PaymentArrearsReminder2 = 'Pamiętaj o\xa0opłaceniu wniosku przed startem ochrony.',
  PaymentArrearsReminder3 = 'Pamiętaj o\xa0opłaceniu wniosku w ciągu 45 minut.',
  PerPerson = '7 dni/osoba',
  Period = 'Okres',
  PersonNotInTheRegistrationCertificate = 'Osoba spoza dowodu rejestracyjnego',
  PeselCoowner1 = 'PESEL współwłaściciela',
  PeselCoowner2 = 'PESEL drugiego współwłaściciela',
  PeselInsurant = 'Numer PESEL osoby zawierającej umowę',
  PeselOwner = 'PESEL właściciela',
  PhoneNumber = 'Numer telefonu',
  PhoneNumberVerified = 'Numer telefonu zweryfikowany!',
  PhonePlaceholder = '500 123 456',
  PhotoSent1 = 'Zdjęcie ',
  PhotoSent2 = 'polisy ',
  PhotoSent3 = ' przesłane prawidłowo!',
  PhotosSent4 = 'zewnętrznej strony',
  PhotosSent5 = 'przesłania zdjęcia rewersu',
  PhotosSent6 = 'wewnętrznej strony',
  PhotosSent7 = 'przesłania zdjęcia polisy',
  PhotosSent8 = 'polisy',
  PhotosSent9 = 'analizy dokumentów',
  PhysicalWorkAvailabilityError1 = 'Rozszerzenie zakresu polisy o\xa0pracę fizyczną jest możliwe dla ubezpieczonych w\xa0wieku\xa018\xa0-\xa069\xa0lat.',
  PhysicalWorkAvailabilityError2 = 'Popraw PESEL lub wiek podróżnego.',
  PleaseAcceptOurPolitic = 'Abyśmy mogli porównać ceny ofert również dla Ciebie, prosimy o zaakceptowanie naszej ',
  PleaseWait = 'Proszę czekać...',
  Poland = 'Polska',
  Policies = 'Polisy',
  PoliciesSold = 'Sprzedanych polis',
  PolicyDownloadFailedMessage = 'Nie udało się pobrać dokumentu. To nic! Polisę wysłaliśmy już na\xa0Twojego maila. W\xa0razie dodatkowych pytań skontaktuj się z\xa0nami pod nr\xa0tel. 22\xa022\xa010\xa0100',
  PolicyDownloadFailedMessageAgency = 'Nie udało się pobrać dokumentu. To nic! Polisę wysłaliśmy już na Twojego maila. W razie dodatkowych pytań skontaktuj się z Zespołem Wsparcia.',
  PolicyNo = 'Nr polisy',
  PolicyOffers = 'Oferty',
  PolicyProtected = 'Polisa zabezpieczona hasłem PESEL',
  PolicySendToEMail = 'Polisę oraz dane do\xa0płatności wysłaliśmy również na\xa0Twojego\nmaila',
  PolicySent = 'Polisę wysłaliśmy również na Twojego maila:',
  PolicyStartTooDistant = 'Zbyt odległy start polisy',
  PolicyValidDate = 'Twoja polisa będzie ważna od dnia',
  PolicyWillBeValid = 'Polisa będzie ważna od dnia',
  PolicyWithDocsSendToEMail = 'Polisę wraz z wymaganymi dokumentami wysłaliśmy również na Twojego maila',
  PopularMakes = 'Popularne marki',
  PreviousDataFound = 'Pobraliśmy Twoje poprzednie dane, aby oszczędzić Twój czas. Sprawdź je i w razie potrzeby zaktualizuj.',
  Price = 'Cena',
  PriceChangedToHigher1 = 'Właśnie zauważyliśmy, że cena Twojej polisy zmieniła się z ',
  PriceChangedToHigher2 = ' na ',
  PriceChangedToHigher3 = '. Towarzystwo ',
  PriceChangedToHigher4 = ' niestety za późno nas o tym poinformowało, ale nie przejmuj się tym!',
  PriceChangedToHigher5 = 'Zweryfikujemy Twoją sprawę jak najszybciej i postaramy się wrócić do ceny ',
  PriceChangedToHigher6 = '. Oddzwonimy do Ciebie i wszystko wyjaśnimy!',
  PriceChangedToLower1 = 'Masz dziś dobry dzień! Właśnie dostaliśmy informację od towarzystwa ',
  PriceChangedToLower2 = ', że Twoja polisa jest jeszcze tańsza!',
  PriceChangedToLower3 = 'Cena zmieniła się z ',
  PriceChangedToLower4 = ' na ',
  PriceChangedToLower5 = 'Bardzo nas to cieszy i mamy nadzieję, że Ciebie również. ',
  PriceFrom = 'od ',
  PriceHasChanged = 'Cena uległa zmianie.',
  PriceIn12Installments = 'Cena w 12 ratach:',
  PriceIn2Installments = 'Cena w 2 ratach:',
  PriceIn3Installments = 'Cena w 3 ratach:',
  PriceIn4Installments = 'Cena w 4 ratach:',
  PriceIn6Installments = 'Cena w 6 ratach:',
  PriceOfYourPolicyIsLower = 'Cena Twojej polisy jest niższa!',
  PrivatePolicy = 'polityki prywatności',
  ProductionYear = 'Rok produkcji',
  ProfileUpdated = 'Zaktualizowano profil',
  PromoInfo1 = 'Rozsyłaj znajomym poniższy link z\xa0',
  PromoInfo2 = 'gwarantowanym bonusem',
  PromoInfo3 = 'Dzięki temu sam ',
  PromoInfo4 = 'zgarniesz bonus do 2000\xa0zł!',
  PromotionCode = 'Kod promocyjny',
  PromotionCodeError = 'Ojjj! Wygląda na to, że zastosowano nieprawidłowy kod rabatowy.\nUpewnij się, że wpisujesz prawidłowy kod, jeśli tak, to zgłoś sprawę do weryfikacji na SUPP.',
  PromotionalCodeInfo1 = 'Gratulacje',
  PromotionalCodeInfo2 = 'bonus',
  PromotionalCodeInfo3 = 'został aktywowany!',
  PurchasingConfirm = 'potwierdzamy zakup ubezpieczenia w\xa0Mubi!',
  PurchasingConfirmNoSession = 'Potwierdzamy zakup ubezpieczenia w\xa0Mubi!',
  Quarantine = 'Kwarantanna',
  QuestionLabel = 'Jaki limit prędkości obowiązuje na autostradach w Polsce?',
  QuotationNumber = 'Nr porównania:',
  RRSO = 'RRSO',
  RateOurCalculator = 'Oceń kalkulator',
  Rating = 'ocen',
  Ratings = 'oceny',
  ReCaptchaInfo = 'Strona jest chroniona przez Google reCAPTCHA.',
  ReCaptchaPrivacyPolicy = 'Polityka prywatności Google',
  ReCaptchaTermsOfService = 'Warunki usługi Google',
  Ready = 'Gotowe!',
  ReadyStep = 'Gotowe',
  RecommendAdvicesSection = 'Kilka porad jak polecać',
  RecommendAdvicesSection1Text1 = 'Pomóż swoim najbliszym oszczędzić, aby mogli skorzystać ze zwrotu ',
  RecommendAdvicesSection1Text2 = '\xa0zł i\xa0wyboru najtańszej polisy w\xa0Mubi.',
  RecommendAdvicesSection1Text3 = 'Polecaj przez SMS: ',
  RecommendAdvicesSection1Text4 = 'wyślij życzliwego SMSa do znajomych lub rodziny z\xa0informacją o\xa0tym, że korzystając z\xa0linka polecającego znajdą najtańszą polisę i\xa0odbiorą ',
  RecommendAdvicesSection1Text5 = '\xa0zł.',
  RecommendAdvicesSection1Text6 = 'Polecaj przez komunikatory (Messenger, WhatsApp itp.): ',
  RecommendAdvicesSection1Text7 = 'podobnie jak w\xa0przypadku SMS możesz zapytać bliskich kiedy kończy im się polisa na auto i\xa0opowiedzieć o\xa0tym, jak można znaleźć najtańszą polisę i\xa0odebrać gwarantowane ',
  RecommendAdvicesSection1Text8 = '\xa0zł!',
  RecommendAdvicesSection1Title = 'Najbliższa rodzina i\xa0znajomi',
  RecommendAdvicesSection2Text1 = 'Wielu z nas na Facebooku ma również dalszych znajomych, z\xa0którymi nie utrzymuje się kontaktu. Możesz dodać wpis na swojej tablicy opisując jak udało Ci się znaleźć tanią polisę w\xa0Mubi i\xa0odebrać Bonus ',
  RecommendAdvicesSection2Text2 = '\xa0zł.',
  RecommendAdvicesSection2Title = 'Tablica na Facebook',
  RecommendAdvicesSection3Text1 = 'Na Facebooku jest wiele grup tematycznych związanych z\xa0okazjami, promocyjnymi ofetami, czy ogólnie z\xa0motoryzacją. Możesz dołączyć do tych grup i\xa0pomagać ich członkom znaleźć najtańszą polisę oraz odebrać Bonus ',
  RecommendAdvicesSection3Text2 = '\xa0zł.',
  RecommendAdvicesSection3Text3 = 'Uważaj jednak aby nie spamować, czyli nie wrzucać tych informacji za często. Wiele grup zabrania reklamowania firm i\xa0usług, dlatego należy robić to w\xa0sposób subtelny i\xa0nie przeszkadzający społeczności.',
  RecommendAdvicesSection3Title = 'Grupy na Facebook ',
  RecommendAndEarn = 'Polecaj i\xa0zarabiaj',
  RecommendEffectivenessSection = 'Jak poprawić swoją skuteczność?',
  RecommendEffectivenessSection1Text1 = 'Często bywa tak, że w\xa0natłoku codziennych obowiązków zapominamy o\xa0pewnych sprawach. Zatroszcz się o\xa0swoich bliskich i\xa0przypomnij im o\xa0zwrocie ',
  RecommendEffectivenessSection1Text2 = '\xa0zł na konto za zakup polisy w\xa0Mubi. Możesz też wspomnieć, że po zakupie również otrzymają możliwość polecania i\xa0dodatkowego zarobku, aż 2000\xa0zł!',
  RecommendEffectivenessSection1Title = 'Zacznij od przypomnienia!',
  RecommendEffectivenessSection2Text = 'Pamiętaj, że codziennie na tablicę Facebooka trafia mnóstwo treści. Podbijaj co jakiś czas swój post - to realna szansa żeby Twoja wiadmość została wyświetlona szerszej grupie odbiorców.',
  RecommendEffectivenessSection2Title = 'Bądź aktywny w\xa0mediach społecznościowcyh!',
  RecommendEffectivenessSection3Text1 = 'Pamiętaj, że nikt nie lubi przepłacać za OC! Bonus ',
  RecommendEffectivenessSection3Text2 = '\xa0zł za zakup to realne obniżenie składki. ',
  RecommendEffectivenessSection3Text3 = 'Samo porównanie kilkudzisiąt ofert daje możliwość zaoszczędzenia nawet kilkuset złotych! ',
  RecommendEffectivenessSection3Title = 'Bądź kreatywny!',
  RecommendInfoPart1 = 'Witamy w Twoim Panelu Partnera Programu Partnerskiego Mubi.',
  RecommendInfoPart2 = 'Cieszymy się, że dołączyłeś do grona Klientów Mubi! Dzięki Tobie wiele osób będzie mogło skorzystać z\xa0gwarantowanych ',
  RecommendInfoPart3 = '\xa0zł wypłacanych prosto na konto po zakupie ubezpieczenia.',
  RecommendInfoPart4 = 'Za każde skuteczne polecenie (zakończone sprzedażą) otrzymasz wynagrodzenie w\xa0wysokości ',
  RecommendInfoPart5 = '\xa0zł. Wyślij swój link lub podaj maile by wysłać zaproszenia.',
  RecommendMubi = 'Po zdobyciu 2000 zł przejdź do programu MGM Business i zarabiaj bez ograniczeń!',
  RecommendationStatsCalculationsTooltipPart1 = 'Liczba znajomych, którzy wykonali kalkulację ubezpieczenia i\xa0zobaczyli porównanie ofert Mubi. To dobry znak - już niewiele dzieli ich od\xa0zakupu polisy.',
  RecommendationStatsClicksTooltipPart1 = 'Kliknięcia to pierwszy krok do\xa0otrzymania bonusu ',
  RecommendationStatsClicksTooltipPart2 = '\xa0zł. Oznaczają że osoby do\xa0których link został wysłany, zainteresowały się treścią wiadomości i\xa0weszły na\xa0kalkulator Mubi.',
  RecommendationStatsPurchasesTooltipPart1 = 'Liczba znajomych, którzy kupili polisę w\xa0Mubi. Już tylko jeden krótki krok dzieli Was od bonusów - opłacenie polisy.',
  RecommendationStatsRepaidPurchasesTooltipPart1 = 'Liczba znajomych, którzy opłacili polisę. Trzymamy kciuki, żeby ta statystyka rosła na\xa0Twoim koncie jak najszybciej! Bonus ',
  RecommendationStatsRepaidPurchasesTooltipPart2 = '\xa0zł otrzymasz za\xa0każdym razem, gdy Twoi znajomi nie odstąpią od\xa0umowy w\xa0ciągu 30\xa0dni i\xa0są naszymi nowymi klientami. Bonus otrzymasz w\xa0okresie 30-60\xa0dni od\xa0zakupu polisy przez znajomego, o\xa0ile uzupełnisz swój numer rachunku bankowego na\xa0koncie klienta Mubi.',
  Recommended = 'Rekomendowane',
  RecommendedMessageForUniqaRenewal = 'Dopasuj propozycję Uniqa w\xa0następnym kroku i\xa0przedłuż ubezpieczenie na\xa0kolejny rok.',
  RecommendedMessageForUniqaRenewalWithDiscount = 'Dopasuj propozycję Uniqa w\xa0następnym kroku i\xa0przedłuż ubezpieczenie na\xa0kolejny rok ze\xa0zniżką 15%.',
  RecommendedOffer = 'Polecana oferta',
  Recommends = 'Poleca ',
  RedirectingTo = 'Trwa przekierowanie do...',
  RefreshSite = 'Odśwież stronę',
  Register = 'Zarejestruj się',
  RegisterAbort = 'Przerwałeś proces rejestracji, spróbuj ponownie',
  Registration = 'Rejestracja',
  RegistrationConfirm = 'Twoje konto zostało założone',
  RegistrationConfirm2 = 'Zaloguj się na swoją pocztę i potwierdź rejestrację w serwisie Mubi.pl',
  RegistrationNumber = 'Numer rejestracyjny',
  RegularPrice = 'Cena regularna: ',
  Regulations = 'Regulamin',
  Remember = 'Pamiętaj! ',
  RemindAboutPayment = 'Pamiętaj o jej opłaceniu przed startem ochrony.',
  RenewalOffer = 'Oferta odnowienia',
  RepeatNewPassword = 'Powtórz nowe hasło',
  ReplacementCar = 'Samochód zastępczy',
  Reply = 'Odpowiedz',
  ReportSent = 'Zgłoszenie zostało wysłane',
  RequestForCallback = 'Zamów rozmowę',
  RequestForCallbackSubTitle = 'Oddzwonimy najszybciej jak to możliwe.',
  RequiredDataInfo = 'Potrzebujemy Twojej zgody i\xa0numeru aby przygotować dla\xa0Ciebie najtańsze oferty polis. Jeśli nie wyrazisz zgody i\xa0nie\xa0zostawisz numeru, Twoje dane zostaną niezwłocznie usunięte.',
  ResendAgain1 = 'Jeśli wiadomość nie doszła - ',
  ResendAgain2 = 'wyślij ponownie',
  ResendTheEmail = 'Wyślij ponownie',
  ResendTheEmailFail403 = 'Podany adres e-mail już jest zarejestrowany. Wprowadź inny.',
  ResendTheEmailFail404 = 'Nie ma takiego adresu e-mail.',
  ResendTheEmailFail429 = 'Wysłaliśmy już do Ciebie wiadomość. Sprawdź skrzynkę pocztową.',
  ResendTheEmailWithLink = 'Wyślij ponownie e-mail z linkiem aktywacyjnym',
  ResetPassword = 'Zresetuj hasło',
  ReverseOfTheRegistrationCertificate = 'Wewnętrzna strona Dowodu Rejestracyjnego',
  ReviewDataFromRegistrationCertificate = 'Przejrzyj i popraw odpowiedzi, które przyjęliśmy na podstawie dowodu rejestracyjnego',
  Save = 'Zapisz',
  SaveComparedInsurance = 'Zapisuj swoje porównania ubezpieczeń OC/AC',
  SaveForm = 'Zapisz formularz',
  SaveInProfile = 'Zapisz w profilu',
  SaveLead = 'Zapisz leada',
  SaveNumber = 'Zapisz numer',
  SaveOver1 = 'Zaoszczędź do ',
  SaveOver2 = '70%',
  SaveOver3 = ' na ubezpieczeniu',
  SaveSettings = 'Zapisz ustawienia',
  SaveVin = 'Zapisz numer VIN',
  SaveYourCarsAndDrivers = 'Zapamiętuj swoje samochody i kierowców',
  SavedCalculations = 'Zapisane kalkulacje',
  ScanFromRegistrationCertificate = 'Zeskanuj telefonem kod z dowodu rejestracyjnego.',
  ScanTheCodeByPhone = 'Zeskanuj kod telefonem:',
  ScanTheCodeFromTheCertificate = 'Zeskanuj kod z dowodu',
  ScanWithPhone = 'Skanuj telefonem',
  ScanWithPhone1 = 'Zeskanuj ',
  ScanWithPhone2 = 'telefonem ',
  ScanWithPhone3 = 'kod Aztec ',
  ScanWithPhone4 = 'z\xa0dowodu rejestracyjnego, aby wczytać dane auta',
  Scope = 'Zakres',
  SecondCoOwner = 'Drugi współwłaściciel',
  SecurityCertificates = 'Certyfikaty bezpieczeństwa',
  SecurityProtectionsMotorTooltipBeesafe = '<h4>Zabezpieczenia antykradzieżowe</h4><div class="content">Jeśli wartość Twojego pojazdu nie przekracza 100&nbsp;000&nbsp;zł wystarczy jedno zabezpieczenie antykradzieżowe. Dla jednośladów o wartości powyżej 100&nbsp;000&nbsp;zł ubezpieczyciel wymaga dwóch zabezpieczeń.</div>',
  SeeMoreOffers = 'Zobacz więcej ofert',
  SeeYourReferralCode = 'Zobacz swój kod polecania',
  SelectAnswer = 'Wybierz odpowiedź, aby przejść dalej',
  SelectDate = 'Wybierz datę',
  SelectFlow = 'Wybierz proces',
  SelectOffer = 'Wybierz ofertę',
  SelectProductionYear = 'Wybierz rok produkcji',
  SelectReason = 'Wybierz powód:',
  SelectedOfferCost = 'Koszt wybranej oferty',
  Send = 'Wyślij',
  SendActivationLink = 'Wyślij link aktywacyjny',
  SendAgain = 'Wyślij\xa0ponownie',
  SendGeneratedTermination = 'Generuj wypowiedzenie',
  SendInvitations = 'Wyślij zaproszenia',
  SendInvitationsOnMail = 'Wyślij zaproszenia na e-mail',
  SendOfferNo = 'Wyślij ofertę nr',
  SendOfferViaEmail = 'Wyślij ofertę na adres e-mail',
  SendOffersViaEmail = 'Wyślij oferty na adres e-mail',
  SendPhoto = 'Prześlij zdjęcie dowodu',
  SendRegistrationCertificatePhoto = 'Wyślij zdjęcie dowodu rejestracyjnego',
  SendSmsWithLink = 'Wyślij SMS z linkiem',
  SendTermination = 'Prześlij wypowiedzenie',
  SendViaEmail = 'Wyślij na maila',
  SendedInvitations = 'Wysłano!',
  Sent = 'Wysłane',
  ServerError = 'Wystąpił błąd serwera',
  ServiceRegulations = 'regulaminu serwisu.',
  Settlements = 'Rozliczenia',
  SettlementsSubHeader = 'To podsumowanie Twoich dotyczasowych działań.',
  ShareInvitationLink = 'Udostępnij link do zaproszenia',
  ShowOffers = 'Pokaż oferty',
  ShowOffersForPoland = 'Pokaż oferty dla Polski',
  ShowOffersWith = 'Pokaż oferty OC z',
  SimpleDocumentDescriptionTitle1 = 'Przygotowanie dokumentów',
  SimpleDocumentDescriptionTitle2 = 'Wykonanie i przesłanie zdjęć',
  SimpleDocumentDescriptionTitle3 = 'Analiza i znalezienie najlepszej oferty',
  SkipThisStep = 'Pomiń ten krok',
  SmsCodeConfirmationsExceeded = 'Wpisałeś nieprawidłowy kod. Limit prób został przekroczony.',
  SmsCodeExpired = 'Kod SMS wygasł. Wygeneruj go ponownie.',
  SmsCodeSentLimitExceeded = 'Dzienny limit SMS-ów został wyczerpany dla podanego numeru telefonu.',
  SmsNotReceived = 'SMS nie dotarł? ',
  SmsResent = 'SMS z kodem został wysłany ponownie',
  SmsResentSuccessfully = 'Wiadomość SMS wysłana ponownie',
  SmsSent1 = 'Na podany numer telefonu ',
  SmsSent2 = ' wysłaliśmy kod SMS',
  Sport = 'Sport',
  SportsInsurance = 'Ubezpieczenie sportowe',
  Ssl = 'SSL',
  Stage = 'Etap:',
  Start = 'Rozpocznij',
  StartAgain = 'Zacznij od nowa',
  Stats = 'Statystyki',
  StatsSubHeader = 'Statystyki Twoich poleceń w\xa0programie partnerskim „Polecaj i\xa0zarabiaj”.',
  StreetName = 'Ulica',
  StreetNumber = 'Numer domu',
  StructuredDataDescription = 'Dzięki kalkulatorowi OC AC Mubi cenę ubezpieczenia samochodu obliczysz już w 3 minuty! Wybierz jedną z nawet 56 ofert i zaoszczędź do 50% na jednej polisie.',
  StructuredDataName = 'Mubi - Kalkulator OC AC Mubi. Sprawdź sobie w 3 min - K. Hołowczyc poleca',
  Subject = 'Temat',
  Sum = 'Suma',
  TakePhoto = 'Zrób zdjęcie',
  TerminationEmailAddress = 'Wpisz swój adres e-mail, na który prześlemy dokument wypowiedzenia',
  TerminationGeneratorChipLabel = 'Dokument gotowy w 2 min',
  TerminationGeneratorHeading = 'Generator Wypowiedzenia\xa0OC',
  TerminationGeneratorModalHeading = 'Twoje wypowiedzenie zostało wysłane na adres e-mail!',
  TerminationGeneratorModalText = 'Dziękujemy za skorzystanie z generatora wypowiedzeń Mubi.',
  TerminationGeneratorSidebarHeading = 'Tak będzie wyglądało Twoje wypowiedzenie:',
  TerminationGeneratorText1 = 'Wypełnij dane właściciela, pojazdu oraz polisy, a\xa0my ',
  TerminationGeneratorText2 = 'wygenerujemy Ci wypowiedzenie\xa0OC, ',
  TerminationGeneratorText3 = 'które możesz przesłać do\xa0swojego Towarzystwa Ubezpieczeń.',
  TerminationInsurancePolicyStartDate = 'Data startu polisy zawartej z\xa0nowym ubezpieczycielem',
  TerminationNewInsurer = 'W jakiej firmie została zawarta nowa umowa?',
  TerminationPreviewArticle28 = 'Na podstawie art. 28 ustawy z\xa0dnia 22 maja 2003\xa0r. o\xa0ubezpieczeniach obowiązkowych, Ubezpieczeniowym Funduszu Gwarancyjnym i\xa0Polskim Biurze Ubezpieczycieli Komunikacyjnych (Dz.U.2019.2214) wypowiadam umowę ubezpieczenia\xa0OC z\xa0ostatnim dniem okresu na jaki została zawarta.',
  TerminationPreviewArticle28a1 = 'Na podstawie art. 28a ustawy z\xa0dnia 22 maja 2003\xa0r. o\xa0ubezpieczeniach obowiązkowych, Ubezpieczeniowym Funduszu Gwarancyjnym i\xa0Polskim Biurze Ubezpieczycieli Komunikacyjnych (Dz.U.2019.2214) wypowiadam umowę ubezpieczenia\xa0OC z\xa0uwagi na zawarcie nowej umowy w\xa0zakresie obowiązkowego ubezpieczenia odpowiedzialności cywilnej posiadaczy pojazdów mechanicznych z\xa0zakładem ubezpieczeń',
  TerminationPreviewArticle28a2 = ' która wystartowała dnia ',
  TerminationPreviewArticle31 = 'Na podstawie art. 31 ustawy z\xa0dnia 22 maja 2003\xa0r. o\xa0ubezpieczeniach obowiązkowych, Ubezpieczeniowym Funduszu Gwarancyjnym i\xa0Polskim Biurze Ubezpieczycieli Komunikacyjnych (Dz.U.2019.2214) wypowiadam umowę ubezpieczenia\xa0OC zawartą przez poprzedniego właściciela pojazdu.',
  TerminationPreviewTerminationReason1art31 = 'Na podstawie art. 31',
  TerminationPreviewText2 = ' ustawy z dnia 22 maja 2003 r. o ubezpieczeniach obowiązkowych, Ubezpieczeniowym Funduszu Gwarancyjnym i Polskim Biurze Ubezpieczycieli Komunikacyjnych (Dz.U.2019.2214) wypowiadam umowę ubezpieczenia OC z ostatnim dniem okresu na jaki została zawarta.',
  TerminationReason = 'Wybierz powód wypowiedzenia OC:',
  TerritorialScope = 'Zakres terytorialny: ',
  ThankYouForPurchasing = 'Dziękujemy za zakup ubezpieczenia w\xa0Mubi i życzymy bezszkodowej jazdy.',
  ThankYouForPurchasingTravel = 'Dziękujemy za zakup ubezpieczenia w\xa0Mubi i\xa0życzymy bezpiecznej podróży!',
  ThanksForYourTips = 'Dziękujemy za Twoje wskazówki!',
  Timetable = 'Harmonogram',
  To = 'do',
  ToReceiveVoucher1 = 'Uwaga, aby odebrać ',
  ToReceiveVoucher2 = ', kliknij ',
  ToReceiveVoucher2InternalTransfer = ', zapłać za polisę, kliknij ',
  ToReceiveVoucher3 = 'tutaj',
  ToReceiveVoucher4 = ' i postępuj zgodnie z instrukcją wyświetloną na stronie.',
  ToReceiveVoucher5 = 'wyślemy w ciągu 30 dni od chwili otrzymania odpowiedzi.',
  ToReceiveVoucher6 = 'Instrukcję odbioru bonusu wyślemy na Twój email.',
  ToReceiveVoucher7 = 'Link do odbioru prześlemy na e-mail po zakupie polisy.',
  Today = 'Dziś',
  Tomorrow = 'Jutro',
  TooDistantPolicyStartdDate = 'Zbyt odległa data startu polisy.',
  TooYoungOwner = 'Oj... aktualnie nie przedstawiamy ofert dla właścicieli poniżej 13\xa0roku życia.',
  TotalCost = 'Koszt całkowity',
  TotalPrice = 'Cena całkowita:',
  TransferDetails = 'Podaj dane do przelewu',
  TravelApplicationPolicyInfo1 = 'Polisę otrzymasz na swój adres e-mail ',
  TravelApplicationPolicyInfo2 = ' po\xa0dokonaniu płatności.',
  TravelCheckoutIndicatorStep1 = 'Wybór oferty',
  TravelCheckoutIndicatorStep2 = 'Dane do polisy',
  TravelCheckoutIndicatorStep3 = 'Akceptacja',
  TravelCheckoutIndicatorStep4 = 'Płatność i polisa',
  TravelIndicatorStep1 = 'Kierunek i okres',
  TravelIndicatorStep2 = 'Dane ubezpieczonych',
  TravelIndicatorStep3 = 'Przejdź do ofert',
  TravelInsurance = 'Kalkulator ubezpieczeń turystycznych',
  TravelInsuranceSecondTitle = 'Porównaj ubezpieczenia podróżne i\xa0zaoszczędź do\xa054%\xa0ceny!',
  TravelMessageForPoland1 = 'Dla podróży krajowych skorzystaj z\xa0dedykowanego rankingu',
  TravelMessageForPoland2 = 'Jeśli podróżujesz za\xa0granicę, usuń Polskę z\xa0listy krajów, które odwiedzisz i\xa0dokończ kalkulację.',
  TravelOfferTooltip = 'Przejdź dalej by\xa0poznać dokładną cenę.',
  TravelPolicyValidInfo1 = 'Ubezpieczenie będzie ważne od\xa0',
  TravelPolicyValidInfo2 = ', jednak nie wcześniej niż\n',
  TravelPolicyValidInfo2AfterPayment = ', jednak dopiero za ',
  TravelPolicyValidInfo3 = ' po opłaceniu składki.',
  TravelPolicyValidReminder = 'Pamiętaj, aby dokonać płatności przed jego rozpoczęciem.',
  TravelPolicyValidReminderAxa = 'Pamiętaj, aby dokonać płatności w ciągu 7 dni.',
  TravelResultsInfo1 = 'W tabeli znajdują się propozycje, które mają charakter informacyjny i nie stanowią oferty w rozumieniu Kodeksu Cywilnego.',
  TravelResultsInfo2 = 'Ceny podane na dzień 24.05.2024 r.',
  TravelResultsRecommendationMessage1 = 'Czy wiesz, że opieka zdrowotna dla wybranego kierunku może być bardzo droga? Rekomendujemy wybranie polisy z\xa0kosztami leczenia minimum 200\xa0000\xa0zł. ',
  TravelResultsRecommendationMessage2 = 'Sugerujemy, aby wybrana oferta obejmowała również OC oraz ubezpieczenie bagażu.',
  TravelResultsRecommendationMessageUSA1 = 'Czy wiesz, że opieka medyczna w\xa0USA jest bardzo droga? ',
  TravelResultsRecommendationMessageUSA2 = 'Wizyta związana ze\xa0złamaną nogą może kosztować nawet 120\xa0000\xa0zł, badanie krwi 4\xa0000\xa0zł, a\xa0transport medyczny do\xa0Polski 250\xa0000\xa0zł. ',
  TravelResultsRecommendationMessageUSA3 = 'Rekomendujemy wybranie polisy z\xa0kosztami leczenia minimum 500\xa0000\xa0zł!',
  TravelThankYouForPurchasing = 'Dziękujemy za zakup ubezpieczenia w\xa0Mubi i życzymy bezpiecznej podróży!',
  Traveler = 'Podróżny',
  TryAgain = 'Spróbuj ponownie',
  TryPayAgain = 'Spróbuj opłacić polisę jeszcze raz.',
  Twitter = 'Twitter',
  TwoSecurity = 'dwa zabezpieczenia',
  TypeManual = 'Wprowadź dane ręcznie',
  TypeNewPassword = 'Nadaj nowe hasło',
  Understand = 'Rozumiem',
  UnexpectedError = 'Wystąpił nieoczekiwany błąd',
  UnexpectedErrorRefreshSite = 'Nieoczekiwany błąd. Odśwież stronę.',
  UploadAPhotoOrScanTheCode = 'Prześlij zdjęcie lub zeskanuj kod ',
  UploadCertificatePhotos = 'Prześlij zdjęcia dowodu',
  UploadDocumentsJustASecond = 'Jeszcze chwilka :)',
  UploadFailed = 'Nie udało się wysłać pliku. Spróbuj ponownie',
  UploadFileButton = 'Wybierz plik',
  UploadObverseRegistrationCertificate1 = 'Prześlij zdjęcie ',
  UploadObverseRegistrationCertificate10 = 'ułatwi nam to szybkie przygotowanie ofert. ',
  UploadObverseRegistrationCertificate11 = 'Jeśli nie, pomiń ten krok, damy sobie radę.',
  UploadObverseRegistrationCertificate2 = 'zewnętrznej strony',
  UploadObverseRegistrationCertificate3 = ' dowodu rejestracyjnego',
  UploadObverseRegistrationCertificate4 = 'Przeciągnij tu zdjęcie',
  UploadObverseRegistrationCertificate5 = 'Wybierz zdjęcia do przesłania',
  UploadObverseRegistrationCertificate6 = 'Wybierz plik',
  UploadObverseRegistrationCertificate7 = 'wewnętrznej strony',
  UploadObverseRegistrationCertificate8 = 'aktualnej polisy',
  UploadObverseRegistrationCertificate9 = 'Jeśli masz pod ręką aktualną polisę , ',
  UploadPhotoOfCurrentPolicy = 'Zdjęcie aktualnej polisy',
  UrlError = 'Nieprawidłowy link',
  UseAddress = 'Użyj adresu',
  UseCalculator = 'Skorzystaj z kalkulatora',
  UseData = 'Użyj danych',
  UserNotExists = 'Konto na podany adres e-mail nie istnieje. Zarejestruj się',
  Vehicle = 'Pojazd',
  VehicleData = 'Dane pojazdu:',
  VehicleDataSection = '1. Dane pojazdu',
  VehicleOwnerData = 'Dane właściciela pojazdu',
  VehicleRegistrationNumber = 'Numer rejestracyjny pojazdu',
  VerifyDataCorrectness = 'Zweryfikuj poprawność danych',
  VersionDetailsEurotax = 'Szczegóły wersji (Eurotax)',
  ViaEmail = 'na adres email',
  ViewOffers = 'Zobacz oferty',
  VinNumber = 'Numer VIN',
  WaitForSms1 = 'Oczekuj na SMS w ciągu ',
  WaitForSms2 = ' sekund.',
  WeFound = 'Znaleźliśmy',
  WeFoundYourLastComparison = 'Odnaleźliśmy Twoje ostatnie porównanie.',
  WeHaveSavedYourRating = 'Zapisaliśmy Twoją ocenę. Dziękujemy!',
  WeRecommend = 'Najczęściej wybierane',
  WeWillEnterTheDataForYou = 'Wprowadzimy dane za Ciebie',
  WeWillNoLongerNotifyAboutThisCar = 'Nie otrzymasz już od nas powiadomień w sprawie tego auta.',
  WeWillNotifyAboutNewCarProtection = 'Skontaktujemy się w sprawie Twojego auta przed końcem ochrony.',
  WelcomeBack = 'Witaj ponownie!',
  WereLookingForMoreOffers = 'Szukamy dla Ciebie ofert',
  WhatCouldWeDoBetter = 'Co moglibyśmy zrobić lepiej?',
  WriteToUsAboutMGMCooperationPart1 = 'Napisz do nas na ',
  WriteToUsAboutMGMCooperationPart2 = ' aby przejść do sekcji MGM Business',
  YearShortcut = '\xa0r.',
  Yes = 'Tak',
  YouCanLogInNow = 'Zaloguj się teraz!',
  YouCanRecommend = 'Odblokowano bonusy dla Ciebie i znajomych!',
  YouCanResign = 'Jeśli jednak się rozmyślisz, możesz odstąpić od umowy w ciągu 30 dni od dziś.',
  YouCanResignTravel1 = 'Jeśli jednak się rozmyślisz, możesz odstąpić od\xa0umowy do\xa0',
  YouCanResignTravel2 = ' dni od\xa0dzisiaj.',
  YouHaveAlreadyAddedARating = 'Już dodałeś ocenę',
  YouWillBeRedirected1 = 'Za chwilę znajdziesz się na stronie',
  YouWillBeRedirected2 = 'gdzie wygodnie dokończysz zakup Twojej polisy',
  YouWontGetAnyUnwantedAdsFromUs = 'Nie dostaniesz od nas niechcianych reklam',
  YourBankTransferDetails = 'Twoje dane do przelewu',
  YourConsents = 'Twoje zgody',
  YourDataIsSafe = 'Twoje dane są bezpieczne i nie przekażemy ich do innych firm.',
  YourEmailAddress = 'Twój e-mail',
  YourNewCar = 'Twój nowy samochód',
  YourPolicyWillBeValid = 'Twoja polisa będzie ważna od dnia',
  YourProfile = 'Twój profil',
  YourRecommendations = 'Twoje polecenia',
  ZL = 'zł',
  ZipCode = 'Kod pocztowy',
  ZipCodePlaceholder = '00-000',
}
