import { AxiosResponse } from 'axios';
import debounce from 'lodash-es/debounce';

import {
  InsuranceTypeEnum,
  QuotationStatusEnum,
  RouteEnum,
  VehicleTypeEnum,
} from 'app/shared/enums';
import { QuotationInterface } from 'app/shared/interfaces/Quotation.interface';
import { JsonPatchValueType } from 'app/shared/types/JsonPatchValue.type';
import { ThunkResult } from 'app/store/helpers';
import { QuotationService } from 'app/services/quotation.service';
import { QuotationActions } from 'app/store/data/quotation/actions';
import {
  checkPromotionCodeFromUrl,
  finalizeNewQuotationForPartnerWithPromotion,
  finalizeNewQuotationWithPromotion,
  finalizeQuotationWithPromotion,
} from 'app/store/data/promotionalCode/thunks';
import { QuotationState } from 'app/store/data/quotation/state.interface';
import { PromotionActions } from 'app/store/data/promotion';
import { selectIsOperator } from 'app/store/data/user';
import {
  getCarModel,
  getQuotationId,
  selectQuotation,
} from 'app/store/data/quotation';
import { QuotationResultActions } from 'app/store/data/quotationResult';

import {
  afterCalculationStatuses,
  hasQuotationExpired,
} from 'app/shared/helpers/quotation';
import { history } from 'app/shared/helpers/getRouterHistory';
import { CalculationDataByModelPathsInterface } from 'app/shared/interfaces/CalculationDataByModelPaths.interface';
import { CepikDataActions } from '../cepikData';

export const getLastQuotation = (
  vehicleType?: VehicleTypeEnum,
  quotationUserId?: string
): ThunkResult<Promise<void | AxiosResponse<QuotationState>>> => (
  dispatch,
  getState
) => {
  const state = getState();
  const isOperator = selectIsOperator(state);
  const carModel = getCarModel(state);

  const shouldNotCreateNewQuotation = vehicleType && !carModel;

  const promise =
    vehicleType && quotationUserId && isOperator
      ? QuotationService.getLastForOperator(vehicleType, quotationUserId)
      : QuotationService.getLast(vehicleType);

  return promise
    .then(({ data: quotation }: AxiosResponse<QuotationInterface>) => {
      if (vehicleType && afterCalculationStatuses.includes(quotation.status)) {
        return QuotationService.requestForCopy(quotation.id).then(
          ({ data: quotationCopy }) => {
            return dispatch(finalizeQuotationInitialization(quotationCopy));
          }
        );
      } else {
        return dispatch(finalizeQuotationInitialization(quotation));
      }
    })
    .catch((error) => {
      if (shouldNotCreateNewQuotation) {
        return Promise.reject(error);
      }

      return dispatch(createNewQuotation(vehicleType, quotationUserId));
    });
};

export const createNewQuotation = (
  vehicleType: VehicleTypeEnum = VehicleTypeEnum.Car,
  quotationUserId?: string
): ThunkResult<Promise<void | AxiosResponse<QuotationState>>> => (
  dispatch,
  getState
) => {
  const state = getState();
  const isOperator = selectIsOperator(state);

  const initialValues: Partial<CalculationDataByModelPathsInterface> = {
    vehicleType,
    scope: {
      components: [InsuranceTypeEnum.Oc],
    },
  };

  const promise =
    quotationUserId && isOperator
      ? QuotationService.createForOperator(initialValues, quotationUserId)
      : QuotationService.create(initialValues);

  return promise.then((response) => {
    const { data: quotation } = response;

    dispatch(QuotationActions.setQuotation(quotation));
    dispatch(finalizeNewQuotationWithPromotion(quotation.id, null, null));

    return response;
  });
};

export const clearQuotation = (): ThunkResult<
  Promise<void | AxiosResponse<QuotationState>>
> => (dispatch, getState) => {
  const state = getState();
  const isOperator = selectIsOperator(state);
  const { id, vehicleType } = selectQuotation(state);

  const initialValues: Partial<CalculationDataByModelPathsInterface> = {
    vehicleType,
    scope: {
      components: [InsuranceTypeEnum.Oc],
    },
  };

  const promise = isOperator
    ? QuotationService.create(initialValues)
    : QuotationService.createWithInheritedABTests(initialValues, id);

  dispatch(QuotationActions.clearQuotation());
  dispatch(CepikDataActions.clearCepikData());

  return promise.then((response) => {
    const { data: quotation } = response;

    dispatch(QuotationActions.setQuotation(quotation));
    dispatch(QuotationActions.setQuotationStatus(QuotationStatusEnum.Cleared));

    return response;
  });
};

export const createNewQuotationForPartner = ({
  brand,
  partnerId,
}: {
  brand: string;
  partnerId: string;
}): ThunkResult<Promise<void | AxiosResponse<QuotationState>>> => (
  dispatch
) => {
  return QuotationService.createForPartner({ brand, partnerId }).then(
    (response) => {
      const { data: quotation } = response;

      dispatch(QuotationActions.setQuotation(quotation));
      dispatch(
        finalizeNewQuotationForPartnerWithPromotion(quotation.id, null, null)
      );

      return response;
    }
  );
};

export const finalizeQuotationInitialization = (
  quotation: QuotationInterface
): ThunkResult => (dispatch) => {
  const isMotor = quotation.vehicleType === VehicleTypeEnum.Motorcycle;
  const promotionCodeFromUrl = isMotor ? '' : checkPromotionCodeFromUrl();

  if (promotionCodeFromUrl || quotation.promotionId) {
    return dispatch(finalizeQuotationWithPromotion(quotation, null, null));
  } else {
    return dispatch(finalizeQuotationWithoutPromotion(quotation));
  }
};

const finalizeQuotationWithoutPromotion = (
  quotation: QuotationInterface
): ThunkResult => (dispatch) => {
  getValidQuotation(quotation).then((validQuotation) => {
    dispatch(QuotationActions.setQuotation(validQuotation));
  });
};

export function getValidQuotation(
  quotation: QuotationInterface
): Promise<QuotationInterface> {
  const quotationExpired = hasQuotationExpired(quotation.startDate);
  if (quotationExpired && afterCalculationStatuses.includes(quotation.status)) {
    return QuotationService.requestForCopy(
      quotation.id
    ).then(({ data: quotationCopy }) => Promise.resolve(quotationCopy));
  }
  return Promise.resolve(quotation);
}

export const restoreQuotation = (quotationId: string): ThunkResult => (
  dispatch
) => {
  dispatch(QuotationActions.clearQuotation());
  dispatch(PromotionActions.clearPromotion());
  history.push({
    pathname: RouteEnum.CALCULATION_FORM,
    state: {
      quotationId,
    },
  });
};

export const updateQuotationDebounced = (
  modelPath: string,
  value: JsonPatchValueType
): ThunkResult => (dispatch, getState) => {
  const state = getState();
  const quotationId = getQuotationId(state);

  const updateQuotation = () => {
    QuotationService.update(quotationId, modelPath, value);
  };

  if (!modelPath) {
    return;
  }

  return debounce(updateQuotation, 100)();
};

/**
 * @param id recoveryKey or quotationId
 */
export const cloneQuotation = (
  id: string
): ThunkResult<Promise<AxiosResponse<QuotationInterface>>> => (dispatch) => {
  return QuotationService.requestForCopy(id).then((response) => {
    dispatch(QuotationResultActions.clearQuotationResult());
    dispatch(QuotationActions.setQuotation(response.data));
    return response;
  });
};
