import { createStyles, Theme, ThemeOptions } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const palette = {
  primary: {
    main: '#ff6600',
    light: '#fff0e6',
    contrastText: common.white,
  },
  background: {
    default: common.white,
  },
};

const shape = {
  borderRadius: 5,
};

const grey: Theme['custom']['grey'] = {
  0: '#f2f2f2',
  1: '#cecece',
  2: '#676767',
  3: '#3d3d3d',
  4: '#9d9d9d',
  5: '#f8f8f8',
  6: '#e0e0e0',
  7: '#8f8f8f',
  8: '#f5f5f5',
  9: '#ececec',
  10: '#9e9e9e',
  11: '#cccccc',
  12: '#d5d5d5',
  13: '#cacaca',
  14: '#ededed',
  15: '#6c6c6d',
};

const green: Theme['custom']['green'] = {
  0: '#239763',
  1: '#4eb285',
  2: '#4a9467',
  3: '#e5f4ed',
};

const blue: Theme['custom']['blue'] = {
  0: '#6086e8',
  1: '#eff3fc',
  2: '#5767cc',
};

const red: Theme['custom']['red'] = {
  0: '#f53b3b',
};

const orange: Theme['custom']['orange'] = {
  0: '#fff7f2',
};

const breakpoints = createBreakpoints({});

const custom: Theme['custom'] = {
  status: {
    success: {
      main: '#78d99f',
      contrastText: '#37b357',
      light: '#eef8f3',
      dark: '#4eb285',
    },
    warning: {
      main: '#fff3eb',
      contrastText: '#ff6600',
      light: 'unset',
      dark: 'unset',
    },
    danger: {
      main: '#ffecef',
      contrastText: '#D43535',
      light: 'unset',
      dark: '#e30613',
    },
    info: {
      main: 'unset',
      contrastText: 'unset',
      light: 'unset',
      dark: 'unset',
    },
  },
  successBackground: '#f1fbf5',
  failBackground: '#fdf2f3',
  grey,
  green,
  blue,
  red,
  orange,
  logo: {
    src: './images/logos/mubi.svg',
    alt: 'Mubi.pl logo',
    width: 76,
    height: 25,
  },
  pageHeader: {
    backgroundColor: common.white,
    title: {
      fontSize: 32,
      color: palette.primary.main,
      fontWeight: 700,
      fontFamily: 'Merriweather',
      [breakpoints.down('xs')]: {
        fontSize: 24,
      },
    },
    darkTitle: {
      color: grey[3],
    },
    secondTitle: {
      color: '#2a2a2a',
      fontFamily: 'Lato',
      fontSize: 18,
      fontWeight: 'normal',
      lineHeight: 1.5,
      paddingTop: 15,
      [breakpoints.down('xs')]: {
        fontSize: 15,
        lineHeight: 1.47,
      },
    },
    subTitle: {
      color: '#9e9e9e',
      lineHeight: 1.5,
      fontSize: 18,
      [breakpoints.down('xs')]: {
        fontSize: 15,
        lineHeight: 1.47,
      },
    },
  },
  loaderModal: {
    backgroundImage:
      'linear-gradient(335deg, rgba(0, 0, 0, 0.15), rgba(255, 255, 255, 0.2)), url("./images/backgrounds/brand-ambassador.png")',
    logoWidth: 144,
    logoMarginBottom: 25,
  },
  pageWidth: 1200,
  facebook: '#3b5998',
  shadow: {
    0: '0 1px 3px 1px rgba(0, 0, 0, 0.08)',
    1: '0 2px 4px 0 rgba(0, 0, 0, 0.16)',
    2: '0 2px 7px 0 rgba(0, 0, 0, 0.2)',
    3: '0 2px 1px rgba(0, 0, 0, 0.08)',
  },
  form: {
    maxWidth: 600,
  },
  progressIndicator: {
    maxWidth: 600,
    marginRight: 340,
  },
};

const MuiListItemText = createStyles({
  primary: {
    fontSize: 12,
    fontWeight: 600,
  },
});

const MuiButton = createStyles({
  root: {
    fontWeight: 'bold',
    fontSize: 15,
  },
  contained: {
    backgroundColor: palette.primary.contrastText,
    '&:hover': {
      color: palette.primary.main,
      backgroundColor: palette.primary.contrastText,
      boxShadow: custom.shadow[1],
    },
    '&$disabled': {
      backgroundColor: custom.grey[0],
      color: '#9E9E9E',
    },
    fontSize: 13,
    color: custom.grey[3],
    fontWeight: 400,
  },
  containedPrimary: {
    boxShadow: custom.shadow[1],
    '&:hover': {
      color: palette.primary.contrastText,
      backgroundColor: palette.primary.main,
      opacity: 0.8,
    },
  },
  sizeLarge: {
    padding: '12px 22px',
    fontSize: 16,
  },
});

const MuiListItemIcon = createStyles({
  root: {
    width: 20,
    marginRight: 0,
    color: palette.primary.main,
  },
});

const MuiList = createStyles({
  padding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

const MuiInputBase = createStyles({
  input: {
    fontSize: 14,
    height: 15,
  },
});

const MuiOutlinedInput = createStyles({
  root: {
    fontSize: 13,
    backgroundColor: common.white,
    '& fieldset': {
      transition: 'all 300ms ease',
    },
    '& fieldset > legend': {
      backgroundColor: common.white,
      transform: 'scale(1.22)',
      transformOrigin: 'left',
      fontSize: 12,
    },
    '&$focused $notchedOutline': {
      borderWidth: 1,
      boxShadow: '0 0 0 3px rgba(255, 102, 0, 0.2)',
      borderRadius: shape.borderRadius,
    },
    '&$disabled $notchedOutline': {
      borderColor: custom.grey[0],
      backgroundColor: custom.grey[0],
    },
    '&$error$focused $notchedOutline': {
      boxShadow: '0 0 0 3px rgba(245, 64, 64, 0.2)',
    },
    '&$error $notchedOutline': {
      borderWidth: 4,
      borderColor: custom.status.danger.contrastText,
    },
    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
      borderColor: custom.grey[2],
    },
    '& $notchedOutline': {
      borderColor: custom.grey[1],
    },
  },
  inputMarginDense: {
    paddingTop: 13,
    paddingBottom: 13,
    height: 20,
    lineHeight: '20px',
  },
  inputMultiline: {
    height: 'unset',
  },
  multiline: {
    lineHeight: 1.5,
    '&$marginDense': {
      paddingTop: 13,
      paddingBottom: 13,
    },
  },
  inputAdornedStart: {
    zIndex: 1,
  },
  input: {
    '&:-webkit-autofill': {
      transitionDelay: '9999s',
      transitionProperty: 'background-color, color',
    },
  },
});

const MuiInputLabel = createStyles({
  root: {
    '&$disabled': {
      color: custom.grey[4],
    },
  },
  outlined: {
    transform: 'translate(14px, 15px) scale(1)',
    fontSize: 13,
    color: custom.grey[4],
    '&$shrink': {
      transform: 'translate(14px, -6px) scale(0.92)',
      color: custom.grey[3],
      backgroundColor: common.white,
    },
    '&$marginDense': {
      transform: 'translate(14px, 17px) scale(1)',
    },
    '&$focused': {
      color: palette.primary.main,
      fontWeight: 600,
    },
    '&$error': {
      color: custom.status.danger.contrastText,
      fontWeight: 600,
    },
  },
});

const MuiSelect = createStyles({
  outlined: {
    height: 20,
    lineHeight: '20px',
    paddingTop: 13,
    paddingBottom: 10,
    width: '100%',
  },
  icon: {
    right: 5,
    color: custom.grey[2],
  },
});

export const newTheme: ThemeOptions = {
  typography: {
    fontFamily: '"Lato", "Helvetica", sans-serif',
    body1: {
      fontSize: '0.875rem',
    },
    body2: {
      lineHeight: 'normal',
      letterSpacing: 'normal',
      fontSize: '16px',
    },
  },
  palette,
  shape,
  custom,
  mixins: {
    toolbar: {
      minHeight: 80,
    },
  },
  overrides: {
    MuiList,
    MuiListItemText,
    MuiListItemIcon,
    MuiButton,
    MuiInputBase,
    MuiOutlinedInput,
    MuiInputLabel,
    MuiSelect,
  },
};
