export enum LegalLinksEnum {
  And = ' oraz ',
  LinkDocuments = 'https://mubi.pl/wazne-dokumenty/',
  LinkInsuranceDistributor = 'https://mubi.pl/dokumenty/informacje-dotyczace-dystrybutora-ubezpieczen-od-01.07.2024.pdf',
  LinkRodo = 'https://mubi.pl/dokumenty/klauzula-rodo.pdf',
  TakLookAt = 'Zapoznaj się z: ',
  TitleILinkDocuments = 'Pełnomocnictwami do wykonywania czynności agencyjnych.',
  TitleInsuranceDistributor = 'Informacjami dotyczącymi agenta ubezpieczeniowego',
  TitleRodo = 'Klauzulą  RODO, ',
}
