export enum PromotionCodeEnum {
  BonusInfo = 'Informacja o\xa0promocji',
  BonusInfoContent1 = 'Pamiętaj, że z bonusu możesz skorzystać',
  BonusInfoContent2 = ' maksymalnie na 2\xa0samochody, na\xa0każdy z\xa0nich tylko raz.',
  BonusInfoContent3 = ' Sprawdziliśmy, że nie możesz już skorzystać z promocji.',
  BonusInfoContent4 = 'Możesz za to polecić znajomym zakup z bonusem 100 zł!',
  BonusInfoContent6 = 'Dzięki temu Ty zgarniesz 100',
  BonusInfoContent7 = ' zł za każde skuteczne polecenie.',
  BonusInfoContent8 = 'Link do polecania znajdziesz w swojej skrzynce mailowej po zakupie polisy.',
  BonusInfoContent9 = 'Sprawdziliśmy, że Twoje konto jest nieaktywne i\xa0nie możesz skorzystać z\xa0tej promocji.',
  CanNotUseOwnVoucher1 = 'Pamiętaj, że ',
  CanNotUseOwnVoucher2 = 'nie możesz polecić sam siebie. ',
  CanNotUseOwnVoucher3 = 'Swój kod promocyjny udostępnij znajomym lub rodzinie.',
  CanNotUseOwnVoucher4 = 'Czy chcesz kontynuować zakup polisy bez kodu promocyjnego?',
  VoucherForAcOnly1 = 'Ten kod promocyjny może być użyty',
  VoucherForAcOnly2 = 'wyłącznie do polis OC+AC',
  VoucherForRenewalOnly1 = 'Ten kod promocyjny może być użyty',
  VoucherForRenewalOnly2 = 'wyłącznie dla odnowień',
  VoucherGeneralError = 'Nie można zastosować bonusu. Kontynuuj zakup polisy bez bonusu lub skontaktuj się z nami pod numerem',
  VoucherOfferPriceTooLow1 = 'Kwota oferty jest za mała',
  VoucherOfferPriceTooLow2 = 'żeby skorzystać z promocji',
}

export enum PromotionCodeResponseErrorCodesEnum {
  AlreadyUsed = 'already-used',
  NotApplicableEmployeeBonus = 'not-applicable-employee-bonus',
  OfferPriceTooLow = 'offer-price-too-low',
  PromotionForAcOnly = 'promotion-for-ac-only',
  PromotionForRenewalOnly = 'promotion-for-renewal-only',
  Referrer = 'referrer',
}
