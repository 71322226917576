export enum FormsEnum {
  AdvertisementCallMeBackForm = 'advertisementCallMeBackForm',
  BlikPaymentForm = 'BlikPaymentForm',
  BonusPayoutForm = 'BonusPayoutForm',
  BonusPayoutVerificationForm = 'BonusPayoutVerificationForm',
  CalculationForm = 'calculationForm',
  CallbackForm = 'callbackForm',
  CheckoutForm = 'checkoutForm',
  CheckoutPaymentAlertForm = 'checkoutPaymentAlertForm',
  CheckoutPaymentForm = 'checkoutPaymentForm',
  CompareCepikDataForm = 'compareCepikDataForm',
  CompleteDataForm = 'completeDataForm',
  ConsentsChangeForm = 'consentsChangeForm',
  ConsentsForm = 'consentsForm',
  DisableNotificationsForm = 'disableNotificationsForm',
  FillVin = 'FillVin',
  LeadsForm = 'leadsForm',
  LeasingLeadForm = 'LeasingLeadForm',
  LoginForm = 'loginForm',
  MubiGarageForm = 'mubiGarageForm',
  MubiInstallmentsDataForm = 'mubiInstallmentsDataForm',
  OfferFilters = 'offerFilters',
  OfferForm = 'offerForm',
  OperatorLeadsForm = 'operatorLeadsForm',
  OperatorLeadsNewCarForm = 'operatorLeadsNewCarForm',
  OrderPayoffForm = 'orderPayoff',
  PasswordChangeForm = 'passwordChangeForm',
  PasswordResetForm = 'passwordResetForm',
  PolicyPasswordForm = 'policyPasswordForm',
  RecommendationForm = 'recommendationForm',
  RecoveryRequestForm = 'recoveryRequestForm',
  RegisterForm = 'registerForm',
  Renewal = 'renewal',
  RenewalOfProposalForm = 'renewalOfProposalForm',
  ResendTheEmailForm = 'resendTheEmailForm',
  SaveNumberForm = 'saveNumberForm',
  ScanWithPhoneForm = 'scanWithPhone',
  SkpRegistrationForm = 'skpRegistrationForm',
  SmsCodeForm = 'SmsCodeForm',
  TerminationGeneratorForm = 'terminationGeneratorForm',
  TravelCheckoutForm = 'travelCheckoutForm',
  TravelForm = 'travelForm',
  TravelOfferForm = 'travelOfferForm',
  TravelPaymentForm = 'travelPaymentForm',
  TravelResultsFilters = 'travelResultsFilters',
  UserEditForm = 'userEditForm',
  UserEmail = 'userEmail',
  UserPhoneNumber = 'userPhoneNumber',
  VerificationForm = 'verificationForm',
  WebToCaseForm = 'webToCaseForm',
}
