export enum ValidationErrorMessagesEnum {
  MaxEmailLengthReached = 'Maksymalna długość adresu e-mail to 70\xa0znaków.',
  MaxPasswordLengthReached = 'Maksymalna długość hasła to 70\xa0znaków.',
  PasswordWhitespaceError = 'Hasło nie może zawierać spacji na początku ani\xa0na końcu.',
  PhoneNumberNotAllowed = 'Nie można użyć tego numeru, wpisz inny numer.',
  RevisedEffectiveDate1 = 'Wybrana przez Ciebie data startu jest inna niż ta, którą zweryfikował ubezpieczyciel, dlatego Twoja polisa rozpocznie się od\xa0',
  RevisedEffectiveDate2 = '.\nPrzechodząc dalej akceptujesz nową datę. W\xa0razie pytań skontaktuj się z\xa0nami pod numerem 22\xa022\xa010\xa0100.',
  RevisedEffectiveDateOperator1 = 'Wybrana przez klienta data startu jest inna niż ta, którą zweryfikował ubezpieczyciel, dlatego polisa rozpocznie się od\xa0',
  RevisedEffectiveDateOperator2 = '.\nUpewnij się, że podana przez klienta data startu jest prawidłowa i\xa0jeśli klient potwierdzi inny start niż\xa0',
  RevisedEffectiveDateOperator3 = ' zaproponuj inne TU.',
}
